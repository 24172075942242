import { Box, Button } from '@mui/material'
import { useState } from 'react'

import { PatientOrProviderDetails } from '../../../components/PatientOrProviderDetails/PatientOrProviderDetails'
import { PatientOrProvider } from '../../../components/PatientOrProviderDetails/types'
import {
  MessagesProvider,
  useMessages,
} from '../../../providers/Messages/Messages.provider'
import { PeerGroupsProvider } from '../../../providers/PeerGroups/PeerGroups.provider'
import { useProviderDetails } from '../../../providers/ProviderDetails/ProviderDetails.provider'
import { ScheduleProvider } from '../../../providers/Schedule/Schedule.provider'
import { VrHeadsetProvider } from '../../../providers/VrHeadset/VrHeadset.provider'
import Messaging from './Messaging/Messaging.container'
import { Schedule } from './Schedule/Schedule.container'
// NEW WIDGET IMPORT
import { Widget } from './types'

export const DashboardWidgets: Widget[] = [
  {
    name: 'Schedule',
    element: (
      <VrHeadsetProvider>
        <PeerGroupsProvider>
          <Schedule />
        </PeerGroupsProvider>
      </VrHeadsetProvider>
    ),
    Provider: ScheduleProvider,
    height: 'half',
    width: 'half',
    usePadding: false,
    Button: () => {
      const { myProviderDetails, myContact } = useProviderDetails()
      const [anchor, setAnchor] = useState<null | HTMLElement>(null)
      const id = anchor ? 'schedule-links' : undefined

      const [myPopover, setMyPopover] = useState(false)

      return (
        <Box>
          <Button
            variant='contained'
            onClick={(e) => {
              setAnchor(e.currentTarget)
              setMyPopover(true)
            }}
            aria-describedby={id}
          >
            Get Schedule Links
          </Button>
          <PatientOrProviderDetails
            id={id}
            open={!!anchor}
            handleClose={() => {
              setAnchor(null)
              setMyPopover(false)
            }}
            anchorEl={anchor}
            type={PatientOrProvider.Provider}
            user={{
              name: `${
                myProviderDetails?.preferredFirstName ||
                myProviderDetails?.firstName
              } ${myProviderDetails?.lastName}`,
              photoUrl: myProviderDetails?.photoUrl,
              providerRole: myProviderDetails?.role,
              patientOrProvider: PatientOrProvider.Provider,
            }}
            contact={myContact!}
            myPopover={myPopover}
          />
        </Box>
      )
    },
  },
  {
    name: 'Messages',
    element: <Messaging />,
    Provider: MessagesProvider,
    height: 'full',
    width: 'half',
    Button: () => {
      const { setIsNewMessage } = useMessages()
      return (
        <Button variant='outlined' onClick={() => setIsNewMessage(true)}>
          New Message
        </Button>
      )
    },
  },
  // NEW WIDGET SPEC
]
