import { FieldValues } from 'react-hook-form'

import { Options } from '../../providers/Options/types'
import { PeerGroupDetails } from '../../providers/PeerGroups/types'
import { syncDateAndTime, syncDateAndTimeLocal } from '../HelperFunctions'

interface Mapper {
  ToAPI: (values: FieldValues, baseValue: any, options: Options[]) => any
  FromAPI: (response: any) => any
}

export const PeerGroupMapper: Mapper = {
  ToAPI: (
    values: FieldValues,
    baseValue: PeerGroupDetails,
    options: Options[]
  ) => {
    const updatedPeerGroup: PeerGroupDetails = {
      ...baseValue,
      ...values,
    }
    // Mapping -> API
    delete updatedPeerGroup.imageUrlForDisplay
    delete updatedPeerGroup.mediaForDisplay

    updatedPeerGroup.minutesBefore = Number(updatedPeerGroup.minutesBefore)
    updatedPeerGroup.minutesAfter = Number(updatedPeerGroup.minutesAfter)
    delete (updatedPeerGroup as any)['minutesBefore,minutesAfter']

    updatedPeerGroup.observers = updatedPeerGroup.observers || []

    updatedPeerGroup.backgroundImageId =
      updatedPeerGroup.backgroundImageId || options[0].apiId

    updatedPeerGroup.startDate = syncDateAndTime(
      values.startDate,
      values.startTime
    )

    updatedPeerGroup.startTime = syncDateAndTime(
      values.startDate,
      values.startTime
    )
    updatedPeerGroup.endDate = syncDateAndTime(
      values.endDate || values.endTime,
      values.endTime
    )
    updatedPeerGroup.endTime = syncDateAndTime(
      values.endDate || values.endTime,
      values.endTime
    )
    // END Mapping -> API

    return updatedPeerGroup
  },
  FromAPI: (peerGroup: PeerGroupDetails) => {
    const mappedPeerGroup: PeerGroupDetails = { ...peerGroup }

    mappedPeerGroup.startDate = syncDateAndTimeLocal(
      peerGroup.startDate,
      peerGroup.startTime
    )
    mappedPeerGroup.startTime = syncDateAndTimeLocal(
      peerGroup.startDate,
      peerGroup.startTime
    )
    mappedPeerGroup.endDate = syncDateAndTimeLocal(
      peerGroup.endDate,
      peerGroup.endTime
    )
    mappedPeerGroup.endTime = syncDateAndTimeLocal(
      peerGroup.endDate,
      peerGroup.endTime
    )

    return mappedPeerGroup
  },
}
