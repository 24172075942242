import { Box, Button, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Modal, ScheduleView } from '../../../../components'
import { usePeerGroups } from '../../../../providers/PeerGroups/PeerGroups.provider'
import { useSchedule } from '../../../../providers/Schedule/Schedule.provider'
import { Appointment, ScheduleDay } from '../../../../providers/Schedule/types'
import { themeColors } from '../../../../theme'
import { dateFormatters } from '../../../../utilities/HelperFunctions'
import { ScheduleDetailView } from './ScheduleDetail.view'

const Schedule = () => {
  const [activeDay, setActiveDay] = useState<Date | null>(null)
  const [schedule, setSchedule] = useState<ScheduleDay[]>([])
  const [isDetailView, setIsDetailView] = useState<boolean>(false)
  const [showCancelModalMode, setShowCancelModalMode] = useState<boolean>(false)

  const [selectedAppointment, setSelectedAppointment] =
    useState<Appointment | null>(null)

  const {
    sevenDayAppointments,
    daysWithAppointments,
    get7dayAppointments,
    getDaysWithAppointments,
    hideAppointment,
  } = useSchedule()

  const { deletePeerGroupSession } = usePeerGroups()

  useEffect(() => {
    setActiveDay(new Date())
  }, [])

  useEffect(() => {
    if (activeDay) {
      get7dayAppointments(activeDay)
      getDaysWithAppointments(activeDay.getMonth(), activeDay.getFullYear())
    }
  }, [activeDay])

  useEffect(() => {
    setIsDetailView(!!selectedAppointment)
  }, [selectedAppointment])

  useEffect(() => {
    // GROUP BY DAY
    const scheduleByDay = sevenDayAppointments.reduce((r, a) => {
      const day = new Date(a.startTime).toDateString()

      const foundAt = r.findIndex((sd: ScheduleDay) => sd.day === day)
      const foundDayOrNew: ScheduleDay =
        foundAt > -1 ? r[foundAt] : { day, appointments: [] }
      foundDayOrNew.appointments.push(a)

      if (foundAt === -1) {
        r.push(foundDayOrNew)
      }
      return r
    }, [] as ScheduleDay[])
    setSchedule(scheduleByDay)
  }, [sevenDayAppointments])

  const removeEventFromCalendar = async (appointmentId: number) => {
    if (activeDay) {
      await hideAppointment(appointmentId)
      get7dayAppointments(activeDay)
    }
  }

  const selectAppointment = (appointment: Appointment) => {
    setSelectedAppointment(appointment)
  }

  const returnToListView = () => {
    setSelectedAppointment(null)
  }

  const handleCancelSession = async () => {
    if (selectedAppointment?.appointmentId) {
      deletePeerGroupSession(selectedAppointment?.appointmentId)
    }
    setActiveDay(new Date())
    returnToListView()
  }

  interface CancelModalProps {
    selectedAppointment: Appointment
    setShowCancelModalMode: Dispatch<SetStateAction<boolean>>
  }

  const CancelModal = ({
    selectedAppointment,
    setShowCancelModalMode,
  }: CancelModalProps) => (
    <Modal
      open={showCancelModalMode}
      setOpen={setShowCancelModalMode}
      title={'CANCEL PEER GROUP MEETING'}
      width={'30%'}
      ModalContent={
        <Box sx={{ width: '92%', margin: 'auto' }}>
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '19px',
              color: themeColors.grayblack,
            }}
          >
            Are you sure you want to cancel this peer group meeting on{' '}
            {dateFormatters.prettyDate(selectedAppointment.startTime)}?
          </Typography>
          <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
            <Button
              variant='contained'
              onClick={() => setShowCancelModalMode(false)}
              sx={{ marginRight: '10px' }}
            >
              No
            </Button>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '17px',
                color: themeColors.gray20,
              }}
            >
              Close window and return to previous page
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
            <Button
              variant='outlined'
              color='error'
              onClick={handleCancelSession}
              sx={{ marginRight: '10px' }}
            >
              Yes
            </Button>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '17px',
                color: themeColors.gray20,
              }}
            >
              Cancel peer group meeting
            </Typography>
          </Box>
        </Box>
      }
    />
  )

  return !(selectedAppointment && isDetailView) ? (
    <ScheduleView
      activeDay={activeDay}
      schedule={schedule}
      daysWithAppointments={daysWithAppointments}
      setActiveDay={setActiveDay}
      removeEventFromCalendar={removeEventFromCalendar}
      selectAppointment={async (appointment) => {
        await selectAppointment(appointment as Appointment)
        return true
      }}
    />
  ) : (
    <Box style={{ padding: '0 10px', height: '100%' }}>
      <ScheduleDetailView
        returnToListView={returnToListView}
        appointment={selectedAppointment!}
        setShowCancelModalMode={setShowCancelModalMode}
      />
      <CancelModal {...{ selectedAppointment, setShowCancelModalMode }} />
    </Box>
  )
}

export { Schedule }
