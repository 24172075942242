import { Storage } from 'aws-amplify'
import { Dispatch, SetStateAction } from 'react'

import { SaveProgress } from '../views/Profile/types'

export const uploadFileToAWS = async ({
  name,
  path,
  file,
  setSaveProgress,
  bucket,
}: {
  name: string
  path: string
  file: File
  setSaveProgress?: Dispatch<SetStateAction<SaveProgress>>
  bucket?: string
}) => {
  const altBucket = bucket ? { bucket } : {}
  const results = await Storage.put(`${path}/${name}.png`, file, {
    ...altBucket,
    progressCallback(progress) {
      if (setSaveProgress) {
        setSaveProgress(
          progress.loaded < progress.total
            ? SaveProgress.InProgress
            : SaveProgress.Completed
        )
      }
    },
  })
  return results
}
