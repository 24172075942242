import {
  Assignment,
  DataThresholding,
  Diversity3,
  EventNote,
  RateReview,
  Settings,
  SupervisorAccount,
} from '@mui/icons-material'
import { useState } from 'react'

import { PeerGroupsProvider } from '../../providers/PeerGroups/PeerGroups.provider'
import { AdminView } from './Admin.view'
import { PeerGroups } from './PeerGroups/PeerGroups.container'
import { AdminSubview } from './types'

export interface AdminViewBaseProps {
  menuItems: AdminSubview[]
  setSelectedMenuItem: React.Dispatch<React.SetStateAction<AdminSubview>>
  selectedMenuItem: AdminSubview
}

const Admin = () => {
  const ADMIN_LINKS: AdminSubview[] = [
    {
      Icon: <Settings />,
      title: 'General',
      restricted: true,
    },
    {
      Icon: <RateReview />,
      title: 'Messages',
      restricted: true,
    },
    {
      Icon: <EventNote />,
      title: 'Schedule',
      restricted: true,
    },
    {
      Icon: <Assignment />,
      title: 'Tasks',
      restricted: true,
    },
    {
      Icon: <Diversity3 />,
      title: 'Peer Groups',
      View: <PeerGroups />,
      Provider: PeerGroupsProvider,
    },
    {
      Icon: <SupervisorAccount />,
      title: 'Patients',
      restricted: true,
    },
    {
      Icon: <DataThresholding />,
      title: 'Report',
      restricted: true,
    },
  ]

  const [selectedMenuItem, setSelectedMenuItem] = useState<AdminSubview>(
    ADMIN_LINKS.filter((x) => !x.restricted)[0]
  )

  return selectedMenuItem.Provider ? (
    <selectedMenuItem.Provider>
      <AdminView
        menuItems={ADMIN_LINKS}
        {...{ selectedMenuItem, setSelectedMenuItem }}
      />
    </selectedMenuItem.Provider>
  ) : (
    <AdminView
      menuItems={ADMIN_LINKS}
      {...{ selectedMenuItem, setSelectedMenuItem }}
    />
  )
}

export { Admin }
