import { Box, Typography } from '@mui/material'
import { useState } from 'react'

import { themeColors } from '../../../theme'

export const DetailRow = ({
  primaryLink,
  scheduleLinkObj,
}: {
  scheduleLinkObj?: {
    meetingName: string
    meetingUrl: string
    duration: number
  }
  primaryLink?: string
}) => {
  const [meetingUrlCopied, setMeetingUrlCopied] = useState<boolean>(false)

  const copyMeetingUrl = (primaryLink: string) => {
    // const htmlContent = `<a href="${primaryLink}">${primaryLink}<a>`
    navigator.clipboard.writeText(primaryLink)
    setMeetingUrlCopied(true)
    setTimeout(() => {
      setMeetingUrlCopied(false)
    }, 1000)
  }

  return (
    <Box
      key={scheduleLinkObj?.meetingUrl}
      sx={{
        width: '100%',
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '14px',
        }}
      >
        {scheduleLinkObj?.meetingName}
      </Typography>

      {!meetingUrlCopied ? (
        <Box
          onClick={() =>
            primaryLink
              ? copyMeetingUrl(primaryLink)
              : scheduleLinkObj?.meetingUrl &&
                copyMeetingUrl(scheduleLinkObj?.meetingUrl)
          }
          sx={{
            fontWeight: 700,
            fontSize: '10px',
            lineHeight: '12px',
            color: themeColors.blue40,
            cursor: 'pointer',
          }}
        >
          COPY
        </Box>
      ) : (
        <Box
          sx={{
            fontWeight: 700,
            fontSize: '10px',
            lineHeight: '12px',
            color: themeColors.green,
          }}
        >
          COPIED!
        </Box>
      )}
    </Box>
  )
}
