import { Storage } from 'aws-amplify'
import { createContext, ReactNode, useContext, useState } from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import {
  MessageData,
  MessagesContextType,
  MessageThreadData,
  MessageUser,
  SendMessageData,
  ThreadsObj,
} from './types'

export const MessagesContext = createContext<MessagesContextType | null>(null)

export const MessagesProvider = ({ children }: { children?: ReactNode }) => {
  const [isNewMessage, setIsNewMessage] = useState(false)

  const [threads, setThreads] = useState<ThreadsObj>({
    myPatients: [],
    providers: [],
    otherPatients: [],
  })

  const [thread, setThread] = useState<MessageThreadData>({
    messageThreadId: 0,
    unread: false,
    messages: [],
    displayUser: {
      name: '',
      role: '',
      photoUrl: '',
      isPatient: false,
      onCareTeam: false,
    },
    displayThreadUserNames: '',
    displayUserCount: 0,
    otherUsers: [],
  })

  // attachments
  const [attachmentKeys, setAttachmentKeys] = useState<
    { id: number; key: string }[]
  >([])

  const { fetch } = useAxios()

  const getProviderMessageThreads = async () => {
    const { data, error } = await fetch({
      path: `Message/GetProviderMessageThreads`,
    })
    if (data) {
      for (const property in data) {
        data[property].forEach(async (d: MessageThreadData) => {
          if (d.displayUser.photoUrl) {
            const decidedPhotoUrl = d.displayUser.photoUrl.startsWith('http')
              ? d.displayUser.photoUrl
              : await Storage.get(d.displayUser.photoUrl)

            d.displayUser.photoUrl = decidedPhotoUrl
          }

          d.messages.forEach(async (m: MessageData) => {
            if (m.fromUser.photoUrl) {
              const decidedPhotoUrl = m.fromUser.photoUrl.startsWith('http')
                ? m.fromUser.photoUrl
                : await Storage.get(m.fromUser.photoUrl)
              m.fromUser.photoUrl = decidedPhotoUrl
            }
            m.toUsers.forEach(async (t: MessageUser) => {
              if (t.photoUrl) {
                const decidedPhotoUrl = t.photoUrl.startsWith('http')
                  ? t.photoUrl
                  : await Storage.get(t.photoUrl)
                t.photoUrl = decidedPhotoUrl
              }
            })

            const notificationCount = Object.values(threads)
              .flat()
              .filter((x) => x.unread).length

            const notificationTitle = notificationCount
              ? `(${notificationCount}) NorthStar-Care Provider Portal`
              : `NorthStar-Care Provider Portal`
            document.title = notificationTitle
            setThreads({ ...data })
          })
        })
      }

      return data
    } else if (error) {
      throw new Error(`Error in getProviderMessageThreads.`)
    }
  }

  // get all the messages in one thread
  const getProviderMessagesInThread = async (threadId: number) => {
    const { data, error } = await fetch({
      path: `Message/GetProviderMessagesInThread?messageThreadId=${threadId}`,
    })
    if (data) {
      data.messages.forEach(async (m: MessageData) => {
        if (m.fromUser.photoUrl) {
          const decidedPhotoUrl = m.fromUser.photoUrl.startsWith('http')
            ? m.fromUser.photoUrl
            : await Storage.get(m.fromUser.photoUrl)
          m.fromUser.photoUrl = decidedPhotoUrl
        }
        m.toUsers.forEach(async (t: MessageUser) => {
          if (t.photoUrl) {
            const decidedPhotoUrl = t.photoUrl.startsWith('http')
              ? t.photoUrl
              : await Storage.get(t.photoUrl)
            t.photoUrl = decidedPhotoUrl
          }
        })
        setThread({ ...data })
      })

      return data
    } else if (error) {
      throw new Error(`Error in getProviderMessagesInThread.`)
    }
  }

  const sendMessage = async (sendMessageData: SendMessageData) => {
    const { error } = await fetch({
      path: `Message/SendProviderMessage`,
      methodType: 'POST',
      body: sendMessageData,
    })
    getProviderMessageThreads()
    if (error) {
      throw new Error(`Error in sendMessage.`)
    }
  }

  return (
    <MessagesContext.Provider
      value={{
        threads,
        getProviderMessageThreads,
        thread,
        getProviderMessagesInThread,
        sendMessage,
        attachmentKeys,
        setAttachmentKeys,
        isNewMessage,
        setIsNewMessage,
      }}
    >
      {children}
    </MessagesContext.Provider>
  )
}

export const useMessages = () =>
  useContext(MessagesContext) as MessagesContextType
