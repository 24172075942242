import { FC } from 'react'

import DoctorDetailed from './../../assets/DoctorDetails.png'
import PageLogo from './../../assets/PageLogo.png'
import { useStyles } from './Auth.styles'

export const AuthSideView: FC = () => {
  const { classes } = useStyles()

  return (
    <div data-testid='auth' className={classes.container}>
      <img src={DoctorDetailed} alt='' role='presentation' />
    </div>
  )
}

export const AuthLogoView: FC = () => {
  const { classes } = useStyles()

  return (
    <div data-testid='auth-logo' className={classes.logoContainer}>
      <img src={PageLogo} alt='' role='presentation' />
    </div>
  )
}
