import { Input } from '@mui/material'
import { useState } from 'react'

import Eye from '../../assets/icon.eye.svg'
import EyeSlash from '../../assets/icon.eye-slash.svg'
import { useStyles } from './RevealPassword.styles'

type RevealPasswordProps = {
  id: string
  value: string
  setValue: (val: string) => void
}

const RevealPassword = ({ id, value, setValue }: RevealPasswordProps) => {
  const { classes } = useStyles()
  const [revealPassword, setRevealPassword] = useState<boolean>(false)

  return (
    <div className={classes.revealPassword}>
      {revealPassword ? (
        <Input
          disableUnderline
          type='text'
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
        />
      ) : (
        <Input
          disableUnderline
          type='password'
          value={value}
          onChange={(evt) => setValue(evt.target.value)}
        />
      )}
      <button
        type='button'
        className={classes.toggle}
        onClick={() => setRevealPassword(!revealPassword)}
      >
        <img
          src={revealPassword ? Eye : EyeSlash}
          alt='reveal password'
          className={classes.icon}
        />
      </button>
    </div>
  )
}

export default RevealPassword
