import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: '100%',
    height: '100vh',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  logoContainer: {
    width: '60%',
    margin: 'auto',

    '& img': {
      width: '100%',
      objectFit: 'fill',
    },
  },
  moduleContainer: {
    width: `calc(68% - ${theme.spacing(5)})`,
    margin: 'auto',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  formContainer: {
    width: '70%',
    margin: 'auto',
  },
}))
