import { CloudSync, FaceRetouchingNatural } from '@mui/icons-material'
import { Box, Button, Slider, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import ReactAvatarEditor from 'react-avatar-editor'

import UserDefault from '../../../assets/User.png'
import { ImageUpload, NavigationLink } from '../../../components'
import { AvatarPickerSpecs, SaveProgress } from '../types'

export interface EditImageProps {
  image?: string
  setUserImage: (canvasEl: HTMLCanvasElement) => void
  handleEditModeToggle: (onOrOff: 'on' | 'off') => void
  saveProgress: SaveProgress
}

export const EditImage = ({
  image,
  setUserImage,
  handleEditModeToggle,
  saveProgress,
}: EditImageProps) => {
  const editor = useRef<AvatarEditor | null>(null)

  const [avatarPickerSpecs, setAvatarPickerSpecs] = useState<AvatarPickerSpecs>(
    {
      image,
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 50,
      width: 200,
      height: 200,
    }
  )

  const handleNewImage = (acceptedImages: File[]) => {
    setAvatarPickerSpecs({ ...avatarPickerSpecs, image: acceptedImages[0] })
  }

  const handleScale = (e: any) => {
    const scale = parseFloat(e.target.value)
    setAvatarPickerSpecs({ ...avatarPickerSpecs, scale })
  }

  const handlePositionChange = (position: { x: number; y: number }) => {
    setAvatarPickerSpecs({ ...avatarPickerSpecs, position })
  }

  const handleSaveImage = () => {
    if (editor) {
      const canvas = editor.current?.getImageScaledToCanvas()
      setUserImage(canvas!)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '700px',
      }}
    >
      <Box>
        <Box sx={{ padding: '5px', margin: 0 }}>
          <ReactAvatarEditor
            ref={editor}
            scale={avatarPickerSpecs.scale}
            width={avatarPickerSpecs.width}
            height={avatarPickerSpecs.height}
            position={avatarPickerSpecs.position}
            onPositionChange={handlePositionChange}
            rotate={avatarPickerSpecs.rotate}
            borderRadius={
              avatarPickerSpecs.width / (100 / avatarPickerSpecs.borderRadius)
            }
            image={avatarPickerSpecs.image || UserDefault}
            crossOrigin='anonymous'
          />
        </Box>
        <Box
          sx={{
            padding: '10px 0',
            width: '70%',
            margin: '0 auto 20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography>Zoom:</Typography>
          <Slider
            sx={{ width: '60%' }}
            name='scale'
            onChange={handleScale}
            min={avatarPickerSpecs.allowZoomOut ? 0.1 : 1}
            max={2}
            step={0.01}
            defaultValue={1}
          />
        </Box>
        <Box sx={{ padding: '10px 0' }}>
          <ImageUpload handleNewImage={handleNewImage} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginTop: 6,
          }}
        >
          <NavigationLink
            direction='backward'
            label='Cancel'
            handleClick={() => handleEditModeToggle('off')}
          />
          <Button
            variant='contained'
            endIcon={
              saveProgress === SaveProgress.InProgress ? (
                <CloudSync />
              ) : (
                <FaceRetouchingNatural />
              )
            }
            onClick={handleSaveImage}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
