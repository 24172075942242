import { Storage } from 'aws-amplify'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { TimeZoneOptions } from '../../utilities/Mocking/Options'
import { useAxios } from '../../utilities/Requests/useAxios'
import {
  BackgroundPhotoOption,
  Credentials,
  MeetingCategories,
  Options,
  OptionsContainers,
  OptionsContextInterface,
  PatientOption,
  ProviderOption,
  States,
  TimeZones,
} from './types'

export const OptionsContext = createContext<OptionsContextInterface>({
  options: {
    providerCredentials: [],
    states: [],
    statesWithoutId: [],
    meetingCategories: [],
    providers: [],
    providerUsers: [],
    patients: [],
    timeZones: [],
    scheduleFrequencies: [],
    peerGroupBackgrounds: [],
  },
})

export const OptionsProvider = ({ children }: { children?: ReactNode }) => {
  const [options, setOptions] = useState<OptionsContainers>({
    providerCredentials: [],
    states: [],
    statesWithoutId: [],
    meetingCategories: [],
    providers: [],
    providerUsers: [],
    patients: [],
    timeZones: [],
    scheduleFrequencies: [],
    peerGroupBackgrounds: [],
  })
  const { fetch } = useAxios()

  const getCredentialsList = useCallback(async () => {
    const { data } = await fetch({
      path: 'Options/GetCredentialsList',
    })
    return data?.map((cr: Credentials, i: number) => ({
      ...cr,
      label: `${cr.longName} (${cr.abbreviation})`,
      value: cr,
      apiId: cr.id,
    }))
  }, [])

  const getStates = useCallback(async (useApiId = true) => {
    const { data } = await fetch({
      path: 'Options/GetStatesList',
    })
    return data?.map((st: States) => ({
      ...st,
      label: st.longName,
      value: st,
      apiId: useApiId ? st.stateId : st.longName,
    }))
  }, [])

  const getTimeZones = useCallback(async () => {
    return TimeZoneOptions.map((tz: TimeZones) => ({
      ...tz,
      value: tz.label,
      apiId: tz.value,
    }))
  }, [])

  const getMeetingCategories = useCallback(async () => {
    const { data } = await fetch({
      path: 'Options/GetMeetingCategories',
    })
    return data?.map((mc: MeetingCategories) => ({
      ...mc,
      value: mc,
      apiId: mc.value,
    }))
  }, [])

  const getProviders = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetAllProviders',
    })

    if (data) {
      const allPromises = data.map(async (p: ProviderOption) => {
        if (p.photoUrl) {
          const decidedPhotoUrl = p.photoUrl.startsWith('http')
            ? p.photoUrl
            : await Storage.get(p.photoUrl)
          p.photoUrl = decidedPhotoUrl
        }
        return p
      })
      //  to ensure every item to finish, use Promise.all()
      const result = (await Promise.all(allPromises))?.map(
        (p: ProviderOption) => ({
          ...p,
          label: `${p.name} (${p.roles[0]})`,
          value: p,
          apiId: p.providerId,
        })
      )
      return result
    } else return []
  }, [])

  const getPatients = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetListOfPatients',
    })
    if (data) {
      const allPromises = data.map(async (p: PatientOption) => {
        if (p.photoUrl) {
          const decidedPhotoUrl = p.photoUrl.startsWith('http')
            ? p.photoUrl
            : await Storage.get(p.photoUrl)
          p.photoUrl = decidedPhotoUrl
        }
        return p
      })
      //  to ensure every item to finish, use Promise.all()
      const result = (await Promise.all(allPromises))?.map(
        (p: PatientOption) => ({
          ...p,
          label: `${p.preferredName} (${p.mrn})`,
          value: p,
          apiId: p.patientId,
        })
      )
      return result
    } else return []
  }, [])

  const getScheduleFrequencies = useCallback(async () => {
    const { data } = await fetch({
      path: 'Options/GetPeerGroupFrequencies',
    })
    return data
      .map((pgf: Options) => ({
        ...pgf,
        apiId: pgf.value,
        label: pgf.label,
        value: pgf.value,
      }))
      .filter((x: Options) => !!x.apiId && x.label !== 'Monthly')
  }, [])

  const getPeerGroupBackgroundOptions = useCallback(async () => {
    const { data } = await fetch({
      path: 'Options/GetBackgroundImages',
    })
    const backgroundOptions = data.map(async (pgf: BackgroundPhotoOption) => ({
      ...pgf,
      apiId: pgf.id,
      label: pgf.timesUsed ? `Used ${pgf.timesUsed} times` : undefined,
      value: pgf.id,
      storageKey: await Storage.get(pgf.storageKey),
    }))

    return await Promise.all(backgroundOptions)
  }, [])

  const loadAllOptions = async () => {
    const allOptions: OptionsContainers = {
      providerCredentials: await getCredentialsList(),
      states: await getStates(),
      statesWithoutId: await getStates(false),
      meetingCategories: await getMeetingCategories(),
      timeZones: await getTimeZones(),
      providers: await getProviders(),
      providerUsers: (await getProviders()).filter((x) => x.isUser),
      patients: await getPatients(),
      scheduleFrequencies: await getScheduleFrequencies(),
      peerGroupBackgrounds: await getPeerGroupBackgroundOptions(),
    }
    setOptions(allOptions)
  }

  useEffect(() => {
    loadAllOptions()
  }, [])

  return (
    <OptionsContext.Provider
      value={{
        options,
      }}
    >
      {children}
    </OptionsContext.Provider>
  )
}

export const useOptions = () => useContext(OptionsContext)
