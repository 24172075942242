import { NavigateNext } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import classnames from 'classnames'
import { useEffect, useState } from 'react'

import VRLogo from '../../assets/icon.vr.svg'
import ZoomLogo from '../../assets/icon.zoom.svg'
import { usePeerGroups } from '../../providers/PeerGroups/PeerGroups.provider'
import { PeerGroupAppointment } from '../../providers/PeerGroups/types'
import { Appointment } from '../../providers/Schedule/types'
import { dateFormatters, dayUtilities } from '../../utilities/HelperFunctions'
import { JoinVRHelpModal } from '../../views/Dashboard/Widgets/Schedule/JoinVRHelpModal'
import { JoinVRModal } from '../../views/Dashboard/Widgets/Schedule/JoinVRModal'
import { useStyles } from './AppointmentCard.styles'

export enum ButtonMode {
  Join,
  Details,
}
export interface AppointmentCardProps {
  buttonMode: ButtonMode
  appointment: Appointment | PeerGroupAppointment
  removeEventFromCalendar?: (appointmentId: number) => void
  addEventToCalendar?: (
    selectedAppointment: Appointment | PeerGroupAppointment
  ) => Promise<boolean>
  selectAppointment?: (
    selectedAppointment: Appointment | PeerGroupAppointment
  ) => void
  // showComingUp?: boolean
}

export const AppointmentCard = ({
  buttonMode = ButtonMode.Join,
  appointment,
  removeEventFromCalendar,
  addEventToCalendar,
  selectAppointment,
}: AppointmentCardProps) => {
  const isCanceled = appointment.status === 'cancelled'
  const { classes } = useStyles()
  const [focus, setFocus] = useState<boolean>(false)
  const [comingUp, setComingUp] = useState<boolean>(false)
  const { joinVRSession } = usePeerGroups()
  const [joinModalOpen, setJoinModalOpen] = useState<boolean>(false)
  const [joinHelpModalOpen, setJoinHelpModalOpen] = useState<boolean>(false)

  useEffect(() => {
    const interval = setInterval(() => {
      let isComingUp = false
      if (appointment.earlinessThreshold && appointment.tardinessThreshold) {
        isComingUp = dayUtilities.isComingUp(
          appointment.startTime,
          appointment.earlinessThreshold,
          appointment.tardinessThreshold
        )
      } else {
        isComingUp = dayUtilities.isComingUp(appointment.startTime)
      }

      if (isComingUp !== comingUp) {
        setComingUp(isComingUp)
      }
    }, 1000)
    return () => clearInterval(interval)
    // }
  }, [
    appointment.startTime,
    appointment.earlinessThreshold,
    appointment.tardinessThreshold,
  ])

  const renderJoinButton = () => {
    if (comingUp && !isCanceled) {
      if (appointment.meetingURL && !appointment.isVr) {
        return (
          <Button
            target='_blank'
            href={appointment.meetingURL}
            variant='contained'
          >
            Join Zoom
          </Button>
        )
      } else {
        return (
          <Button
            // target='_blank'
            // href=''
            variant='contained'
            onClick={(e) => {
              e.stopPropagation()
              // join vr session call
              // @ts-ignore
              joinVRSession(appointment.appointmentId!)
              setJoinModalOpen(true)
            }}
          >
            Join VR
          </Button>
        )
      }
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      data-testid='appointmentCard'
      className={classnames(
        classes.appointment__card,
        isCanceled && classes.appointment__card_cancelled
      )}
      onMouseEnter={() => setFocus(true)}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseLeave={() => setFocus(false)}
      onClick={() =>
        selectAppointment && !joinModalOpen && !joinHelpModalOpen
          ? selectAppointment(appointment)
          : null
      }
    >
      {joinModalOpen && (
        <JoinVRModal
          showJoinModal={joinModalOpen}
          setShowJoinModal={setJoinModalOpen}
          handleHelpNavigation={() => {
            setJoinHelpModalOpen(true)
            setJoinModalOpen(false)
          }}
        />
      )}
      {joinHelpModalOpen && (
        <JoinVRHelpModal
          showJoinHelpModal={joinHelpModalOpen}
          setShowJoinHelpModal={setJoinHelpModalOpen}
        />
      )}
      <Grid
        container
        sx={(theme) => ({
          minHeight: theme.spacing(7),
          margin: 'auto',
        })}
      >
        <Grid
          item
          xs={2}
          className={classnames(
            classes.appointment__card__leftContent,
            isCanceled && classes.appointment__card__leftContent_canceled
          )}
        >
          <div>
            <Typography
              className={classes.appointment__card__leftContent__startTime}
            >
              {dateFormatters.time(appointment.startTime)}
            </Typography>
            <Typography
              className={classes.appointment__card__leftContent__duration}
            >
              {dateFormatters.duration(
                appointment.startTime,
                appointment.endTime
              )}
            </Typography>
          </div>

          <img src={appointment.isVr ? VRLogo : ZoomLogo} alt='' />
        </Grid>
        <Grid item xs={10} container alignItems={'center'}>
          <Grid
            item
            xs={11}
            className={classes.appointment__card__rightContent}
          >
            <Typography
              className={classes.appointment__card__rightContent__title}
            >
              {isCanceled && 'CANCELLED: '}
              {appointment.name}
            </Typography>
            <Grid
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Typography
                className={
                  classes.appointment__card__rightContent__participants
                }
              >
                {appointment.participantCount} participant
                {appointment.participantCount > 1 && 's'}
              </Typography>

              {renderJoinButton()}

              {isCanceled && removeEventFromCalendar && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    removeEventFromCalendar(appointment.id)
                  }}
                  variant='outlined'
                  color='error'
                >
                  Remove
                </Button>
              )}
              {buttonMode === ButtonMode.Join && addEventToCalendar && (
                <Button
                  onClick={() => addEventToCalendar(appointment)}
                  disabled={
                    (appointment as PeerGroupAppointment).isFull ||
                    (appointment as PeerGroupAppointment).isSignedUp
                  }
                  variant={
                    (appointment as PeerGroupAppointment).isFull ||
                    (appointment as PeerGroupAppointment).isSignedUp
                      ? 'contained'
                      : 'outlined'
                  }
                >
                  {(appointment as PeerGroupAppointment).isSignedUp
                    ? 'Joined'
                    : 'Add to Schedule'}
                </Button>
              )}
            </Grid>
          </Grid>
          {buttonMode === ButtonMode.Details &&
            focus &&
            appointment.status !== 'cancelled' && (
              <Grid item xs={1}>
                <NavigateNext fontSize='large' sx={{ marginLeft: '-10px' }} />
              </Grid>
            )}
        </Grid>
      </Grid>
    </div>
  )
}
