import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
import utc from 'dayjs/plugin/utc'

import {
  PeerGroupAppointment,
  PeerGroupScheduleDay,
} from '../providers/PeerGroups/types'
import {
  Appointment,
  DayWithAppointments,
  ScheduleDay,
} from '../providers/Schedule/types'

export const getUserInitials = (name: string) => {
  return name
    .split(' ')
    .map((np) => np[0].toUpperCase())
    .slice(0, 2)
    .join(' ')
}

export const truncateFileName = (name: string, desiredLength = 10) => {
  const nameParts = name.split('.')
  const extension = nameParts.pop()
  return `${nameParts.join('').substring(0, desiredLength)}${
    nameParts.join().length > desiredLength ? '...' : '.'
  }${extension}`
}

export const generateRandomPassword = (passwordLength: number) => {
  const numberChars = '0123456789'
  const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowerChars = 'abcdefghijklmnopqrstuvwxyz'
  const allChars = numberChars + upperChars + lowerChars
  let randPasswordArray = Array(passwordLength)
  randPasswordArray[0] = numberChars
  randPasswordArray[1] = upperChars
  randPasswordArray[2] = lowerChars
  randPasswordArray = randPasswordArray.fill(allChars, 3)
  return shuffleArray(
    randPasswordArray.map(function (x) {
      return x[Math.floor(Math.random() * x.length)]
    })
  ).join('')
}

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

const APPOINTMENT_TIME_FORMAT = 'h:mm A'
// const PRETTY_FULL_DATE_FORMAT = 'MMMM D, YYYY'
const PRETTY_FULL_DATE_AND_TIME_FORMAT = 'MM/DD/YYYY h:mm A'
const SHORT_DISPLAY_FORMAT = 'MM/DD/YYYY'
const DAY_OF_WEEK_FORMAT = 'dddd'

dayjs.extend(isYesterday)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(duration)

export const dateFormatters = {
  time: (date: string) => dayjs(date).format(APPOINTMENT_TIME_FORMAT),
  prettyDate: (dateString: string) => {
    const day = dayjs(dateString)
    const baseDate = day.format(SHORT_DISPLAY_FORMAT)
    const prepend = day.isYesterday()
      ? 'Yesterday'
      : day.isToday()
      ? 'Today'
      : day.isTomorrow()
      ? 'Tomorrow'
      : day.format('dddd')
    return `${prepend}, ${baseDate}`
  },
  prettyDateWithTime: (date: string) =>
    dayjs(date).format(PRETTY_FULL_DATE_AND_TIME_FORMAT),
  shortenedCleanDate: (date: Date) => dayjs(date).format(SHORT_DISPLAY_FORMAT),
  duration: (startTime: string, endTime: string) => {
    const dayjsduration = dayjs.duration(dayjs(endTime).diff(dayjs(startTime)))
    const hours = dayjsduration.hours() ? `${dayjsduration.hours()}h ` : ''
    const minutes = dayjsduration.minutes() ? `${dayjsduration.minutes()}m` : ''
    return `${hours}${minutes}`
  },
  imageLabel: () => dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
  dayOfWeek: (day: number) => dayjs().day(day).format(DAY_OF_WEEK_FORMAT),
  meetingTimes: (startTime: Date, endTime: Date) =>
    `${dayjs(startTime).format(APPOINTMENT_TIME_FORMAT)} - ${dayjs(
      endTime
    ).format(APPOINTMENT_TIME_FORMAT)}`,
}

dayjs.extend(isBetween)
export const dayUtilities = {
  isComingUp: (
    startTime: string,
    openBeforeInMinutes = 10,
    openAfterInMinutes = 10
  ): boolean => {
    return dayjs().isBetween(
      dayjs(startTime).subtract(openBeforeInMinutes, 'minutes'),
      dayjs(startTime).add(openAfterInMinutes, 'minutes')
    )
  },
}

dayjs.extend(utc)
export const syncDateAndTime = (date: Date, time: Date): Date => {
  return dayjs()
    .set('year', dayjs(date).year())
    .set('month', dayjs(date).month())
    .set('date', dayjs(date).date())
    .set('hour', dayjs(time).hour())
    .set('minute', dayjs(time).minute())
    .set('second', dayjs(time).second())
    .toDate()
}

export const syncDateAndTimeLocal = (
  date: any,
  time: any
): Date | undefined => {
  return date && time
    ? new Date(`${date.split('T')[0]}T${time.split('T')[1]}`)
    : new Date()
}

export const getStartAndEndDateFromMonthYear = (
  month: number,
  year: number
): { startDate: string; endDate: string } => {
  const date = dayjs().month(month).year(year)
  return {
    startDate: date.startOf('month').toISOString(),
    endDate: date.endOf('month').toISOString(),
  }
}

export const divideScheduleIntoDays = (
  rawAppointments: Appointment[] | PeerGroupAppointment[],
  beginningState: ScheduleDay[] | PeerGroupScheduleDay[] = []
) =>
  (rawAppointments as any).reduce((r: any, a: any) => {
    const day = new Date(a.startTime).toDateString()

    const foundAt = r.findIndex((sd: ScheduleDay) => sd.day === day)
    const foundDayOrNew: ScheduleDay =
      foundAt > -1 ? r[foundAt] : { day, appointments: [] }
    foundDayOrNew.appointments.push(a)

    if (foundAt === -1) {
      r.push(foundDayOrNew)
    }
    return r
  }, beginningState)

export const divideScheduleIntoDaysWithAppointments = (
  schedule: Date[]
): DayWithAppointments[] => {
  const days = (schedule as any).reduce((r: any, a: any) => {
    const day = new Date(a).getDate()
    const foundAt = r.findIndex((sd: DayWithAppointments) => sd.day === day)
    const foundDayOrNew: ScheduleDay =
      foundAt > -1 ? r[foundAt] : { day, appointments: [] }
    foundDayOrNew.appointments.push(a)

    if (foundAt === -1) {
      r.push(foundDayOrNew)
    }
    return r
  }, [])
  return days.map((sd: ScheduleDay) => ({
    day: sd.day,
  }))
}
