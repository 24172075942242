import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Control, FieldValues, useForm } from 'react-hook-form'

import { Phase } from '../../../components/Progress/ProgressWithLabel'
import { useOptions } from '../../../providers/Options/Options.provider'
import { Options } from '../../../providers/Options/types'
import { usePeerGroups } from '../../../providers/PeerGroups/PeerGroups.provider'
import {
  PeerGroupAppointment,
  PeerGroupMedia,
} from '../../../providers/PeerGroups/types'
import {
  getFieldValidationSchema,
  getInputFieldBasedOnType,
} from '../../../utilities/Forms/SectionFields'
import { Field, Section, SectionFields } from '../../../utilities/Forms/types'
import { getViewFieldBasedOnType } from '../../../utilities/Forms/ViewFields'
import { PeerGroupMapper } from '../../../utilities/Requests/apiMappers'
import { uploadFileToAWS } from '../../../utilities/Storage'
import { PEER_GROUP_FIELDS } from './PeerGroupFields'
import { PeerGroupsView } from './PeerGroups.view'
import { ViewType } from './types'

const PeerGroups = () => {
  const {
    peerGroups,
    peerGroupDetails,
    getPeerGroupList,
    getPeerGroupDetails,
    addPeerGroup,
    updatePeerGroup,
    getPeerGroupSchedule,
    joinPeerGroupSession,
    deletePeerGroup,
  } = usePeerGroups()

  const [viewMode, setViewMode] = useState<ViewType>(ViewType.List)
  const [peerGroupId, setPeerGroupId] = useState<number | null>(null)
  const [peerGroupDetailFields, setPeerGroupDetailFields] =
    useState<SectionFields>(PEER_GROUP_FIELDS)
  const [peerGroupDetailsEditMode, setPeerGroupDetailsEditMode] =
    useState<boolean>(true)
  const [imageValue, setImageValue] = useState<number>(0)
  const [activePhase, setActivePhase] = useState<Phase>({
    label: 'Filling form',
    total: 1,
    value: 0,
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [showJoinModal, setShowJoinModal] = useState<boolean>(false)
  const [showCancelModalMode, setShowCancelModalMode] = useState<boolean>(false)

  const { options } = useOptions()
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(getFieldValidationSchema(PEER_GROUP_FIELDS)),
  })

  const watchedFieldsList = [
    ...PEER_GROUP_FIELDS.map((section: Section) =>
      section.fields
        .flat()
        .filter((x: Field) => x.watch)
        .map((x: Field) => x.backendKey)
    ).flat(),
  ]

  const watchedFields: { name: string; value: any }[] = watchedFieldsList.map(
    (wf) => ({
      name: wf,
      value: watch(wf),
    })
  )

  const handleRowClick = (peerGroupId: number) => {
    setPeerGroupId(peerGroupId)
    setViewMode(ViewType.Details)
  }

  const goToNewPeerGroup = () => {
    setPeerGroupId(null)
    setViewMode(ViewType.Add)
  }

  useEffect(() => {
    const detailFieldsCp = { ...peerGroupDetailFields }
    detailFieldsCp[0].sectionLabel = `${
      viewMode === ViewType.Edit
        ? 'Update Peer Group'
        : viewMode === ViewType.Add
        ? 'New Peer Group'
        : ''
    } `
    setPeerGroupDetailFields(detailFieldsCp)
  }, [viewMode])

  useEffect(() => {
    if (peerGroupId) {
      getPeerGroupDetails(peerGroupId)
      setPeerGroupDetailsEditMode(true)
    } else {
      getPeerGroupDetails()
      setPeerGroupDetailsEditMode(false)
    }
  }, [peerGroupId])

  useEffect(() => {
    setPeerGroupDetailsEditMode(
      viewMode === ViewType.Add || viewMode === ViewType.Edit
    )
  }, [viewMode])

  useEffect(() => {
    setActivePhase({ ...activePhase, value: imageValue })
  }, [imageValue])

  const onSubmit = async (values: FieldValues) => {
    setSubmitting(true)
    if (values.media.length) {
      setActivePhase({
        label: 'Uploading media',
        total: values.media.length,
      })

      const mediaList = [...values.media]

      mediaList.forEach(async (mediaVal: PeerGroupMedia, i: number) => {
        if (!mediaVal.file) return null

        setImageValue(i + 1)

        const results = await uploadFileToAWS({
          name: mediaVal.file.name,
          path: `peer-group-attachments/${values.groupName}`,
          file: mediaVal.file,
          bucket: process.env.REACT_APP_MEDIA_BUCKET,
        })

        delete mediaVal.file

        mediaList.push({
          ...mediaVal,
          fileKey: results.key,
        })
      })

      values.media = mediaList
    }

    try {
      setActivePhase({
        label: 'Saving',
      })
      const updatedPeerGroup = PeerGroupMapper.ToAPI(
        values,
        peerGroupDetails,
        options.peerGroupBackgrounds
      )
      if (viewMode === ViewType.Add) {
        await addPeerGroup(updatedPeerGroup)
        setViewMode(ViewType.List)
      } else if (viewMode === ViewType.Edit) {
        await updatePeerGroup(updatedPeerGroup)
        setViewMode(ViewType.Details)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSubmitting(false)
    }
  }

  // SET INITIAL VALUES
  const addInputFields = (
    detailFields: Field[],
    options: Options,
    errors: any,
    control: Control<FieldValues, any>,
    watchedFields: { name: string; value: any }[] | null
  ) =>
    detailFields.map((detailField) => ({
      ...detailField,
      editComponent: getInputFieldBasedOnType({
        watchedFields,
        field: detailField,
        options,
        errors,
        control,
      }),
      viewComponent: getViewFieldBasedOnType({
        field: detailField,
        options,
        value: peerGroupDetails
          ? (peerGroupDetails as any)[
              detailField.renderKey || detailField.backendKey || ''
            ]
          : null,
        existingValues: peerGroupDetails,
      }),
    }))

  useEffect(() => {
    if (
      ((viewMode === ViewType.Details || viewMode === ViewType.Edit) &&
        peerGroupDetails) ||
      ViewType.Add
    ) {
      const formVersionOfDetails = peerGroupDetailFields.map((section) => ({
        ...section,
        fields: addInputFields(
          section.fields,
          options,
          errors,
          control,
          watchedFields
        ),
      }))
      setPeerGroupDetailFields(formVersionOfDetails)
    }
  }, [peerGroupDetails, options, errors, viewMode])

  // SET VALUES FOR INITIAL VALUES ON EDIT
  useEffect(() => {
    peerGroupDetailFields.forEach((section: Section) => {
      section.fields.forEach((field: Field) => {
        const multipartField = field.backendKey.split(',')
        if (multipartField.length > 1) {
          multipartField.forEach((mpf, i) => {
            setValue(
              mpf,
              peerGroupDetails
                ? (peerGroupDetails as any)[field.backendKey][i]
                : field.initialValue[i]
            )
          })
        } else {
          setValue(
            field.backendKey,
            peerGroupDetails
              ? (peerGroupDetails as any)[field.backendKey]
              : field.initialValue
          )
        }
      })
    })
  }, [peerGroupDetails, options])

  const handleJoinSessionModal = async () => {
    await getPeerGroupSchedule(new Date())
    setShowJoinModal(true)
  }

  const handleJoinSession = async (
    peerGroupSession: PeerGroupAppointment
  ): Promise<boolean> => {
    return await joinPeerGroupSession(
      peerGroupSession.peerGroupSessionId,
      peerGroupSession.startTime
    )
  }

  const handleCancelPeerGroup = async () => {
    if (peerGroupId) {
      await deletePeerGroup(peerGroupId)
      setShowCancelModalMode(false)
      setPeerGroupId(null)
      setViewMode(ViewType.List)
    }
  }

  return (
    peerGroups && (
      <PeerGroupsView
        {...{
          viewMode,
          submitting,
          setViewMode,
          setPeerGroupId,
          peerGroupId,
          peerGroups,
          peerGroupDetails,
          getPeerGroupList,
          getPeerGroupDetails,
          addPeerGroup,
          updatePeerGroup,
          watchedFields,
          reset,
          showJoinModal,
          setShowJoinModal,
          showCancelModalMode,
          setShowCancelModalMode,
        }}
        {...{ peerGroupDetailsEditMode, setPeerGroupDetailsEditMode }}
        peerGroupDetailFields={peerGroupDetailFields}
        handleRowClick={handleRowClick}
        goToNewPeerGroup={goToNewPeerGroup}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        activePhase={activePhase}
        handleJoinSessionModal={handleJoinSessionModal}
        handleJoinSession={handleJoinSession}
        handleCancelPeerGroup={handleCancelPeerGroup}
      />
    )
  )
}

export { PeerGroups }
