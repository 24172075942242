import { Person } from '@mui/icons-material'
import { Typography } from '@mui/material'

export const FormInstructions = () => (
  <Typography
    sx={{
      fontWeight: 700,
      fontSize: 12,
      marginBottom: 1,
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Person fontSize='small' /> = Visible to Patients, * = Required
  </Typography>
)
