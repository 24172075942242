import '@aws-amplify/ui-react/styles.css'

import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react'
import { Amplify } from 'aws-amplify'
import { Route, Routes } from 'react-router-dom'

import { awsConfig } from './awsConfig'
import { Mock } from './components'
import { PatientCareTeamProvider } from './providers/CareTeam/PatientCareTeam.provider'
import { useNavigation } from './providers/Navigation/Navigation.provider'
import { RequireAuth } from './providers/Navigation/RequireAuth.Wrapper'
import { OptionsProvider } from './providers/Options/Options.provider'
import { ProviderDetailsProvider } from './providers/ProviderDetails/ProviderDetails.provider'
import { UserProvider } from './providers/User/User.provider'
import { Auth, Logout } from './views/Auth/Auth.container'
import { Main } from './views/Main/Main.container'
Amplify.configure(awsConfig)

// TODO ERROR AND LOADING STATES
export const App = () => {
  const { navigation } = useNavigation()

  return (
    <AmplifyProvider>
      <Authenticator.Provider>
        <UserProvider>
          <PatientCareTeamProvider>
            <ProviderDetailsProvider>
              <OptionsProvider>
                <Routes>
                  <Route path={'/'} element={<Auth />}></Route>
                  <Route path={'/logout'} element={<Logout />}></Route>
                  {navigation!.allRoutes
                    .filter((page) => !page.restricted)
                    .map(({ route, View, useViewPaper, Provider }) => (
                      <Route
                        path={`/${route}`}
                        element={
                          <RequireAuth>
                            <Main usePaper={useViewPaper}>
                              {View ? (
                                Provider ? (
                                  <Provider>{View}</Provider>
                                ) : (
                                  View
                                )
                              ) : (
                                <Mock route={route} />
                              )}
                            </Main>
                          </RequireAuth>
                        }
                        key={route}
                      />
                    ))}
                </Routes>
              </OptionsProvider>
            </ProviderDetailsProvider>
          </PatientCareTeamProvider>
        </UserProvider>
      </Authenticator.Provider>
    </AmplifyProvider>
  )
}
