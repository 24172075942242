import { Box, Paper, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

import { useStyles } from './Widget.styles'

export interface WidgetProps {
  title: string
  Button?: FC
  children?: ReactNode
  usePadding?: boolean
}

export const Widget = ({
  title,
  Button,
  children,
  usePadding = true,
}: WidgetProps) => {
  const { classes } = useStyles()

  return (
    <Paper sx={{ height: '100%' }}>
      <div className={classes.widgetHeader}>
        <Box sx={{ flexGrow: 2 }}>
          <Typography variant='h6'>{title.toUpperCase()}</Typography>
        </Box>
        {Button && <Button />}
      </div>
      <Box
        sx={{ padding: usePadding ? '15px' : '0px' }}
        className={classes.widgetContent}
      >
        {children}
      </Box>
    </Paper>
  )
}
