import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Box, Button, Chip, IconButton } from '@mui/material'
import { Storage } from 'aws-amplify'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
// import htmlToDraft from 'html-to-draftjs'
import { SetStateAction, useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'

import FileSvg from '../../../../assets/file.svg'
import { FlagSvg } from '../../../../assets/FlagSvg.tsx'
import {
  NavigationLink,
  PatientOrProviderDetails,
} from '../../../../components'
import { PatientOrProvider } from '../../../../components/PatientOrProviderDetails/types'
import { usePatientCareTeam } from '../../../../providers/CareTeam/PatientCareTeam.provider'
import { useMessages } from '../../../../providers/Messages/Messages.provider'
import {
  MessageThreadData,
  SendMessageData,
  UploadObj,
} from '../../../../providers/Messages/types'
import { useOptions } from '../../../../providers/Options/Options.provider'
import { RecipientOptionObj } from '../../../../providers/Options/types'
import { useProviderDetails } from '../../../../providers/ProviderDetails/ProviderDetails.provider'
import { themeColors } from '../../../../theme'
import { useStyles } from './Messaging.styles'

type MessageEditorProps = {
  backToMessages: () => void
  uploads: UploadObj[]
  setUploads: React.Dispatch<React.SetStateAction<UploadObj[]>>
  threadId?: number
  thread?: MessageThreadData
  setAttachmentKeys?: React.Dispatch<
    React.SetStateAction<{ id: number; key: string }[]>
  >
  setIsNewMessage?: React.Dispatch<React.SetStateAction<boolean>>
  isNewMessage?: boolean
}

const MessageEditor = ({
  backToMessages,
  uploads,
  setUploads,
  threadId,
  thread,
  setAttachmentKeys,
  setIsNewMessage,
  isNewMessage,
}: MessageEditorProps) => {
  const { classes } = useStyles()

  const { sendMessage } = useMessages()
  const { myProviderDetails } = useProviderDetails()
  const { getProviderContactInfo, providerContactInfo } = usePatientCareTeam()

  const [showReply, setShowReply] = useState(true)

  const editorRef = useRef(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [urgency, setUrgency] = useState(false)

  const [sendMessageData, setSendMessageData] = useState<SendMessageData>({
    toProviders: [],
    toPatient: 0,
    urgent: urgency,
    messageContent: '',
    attachments: [],
  })

  const [disableSend, setDisableSend] = useState(true)

  const [showDropdown, setShowDropdown] = useState(false)
  const [searchRecipientStr, setSearchRecipientStr] = useState('')

  // for patient care team / provider detail popover
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [clickedPopover, setClickedPopover] = useState({
    isProvider: false,
    username: '',
    photoUrl: '',
    patientMrn: '',
    providerRole: '',
    providerOrPatientId: 0,
    uniqueId: '',
  })

  const { options } = useOptions()

  const initialRecipientOptions = [
    ...options.providers.map((opt) => ({
      ...opt,
      isProvider: true,
      selected: false,
    })),
    ...options.patients.map((opt) => ({
      ...opt,
      isProvider: false,
      selected: false,
    })),
  ]?.filter(
    (option: RecipientOptionObj) =>
      !(option.isProvider && option.apiId === myProviderDetails?.providerId)
  )
  const [recipientOptions, setRecipientOptions] = useState(
    initialRecipientOptions
  )

  const getSelected = (isSelected: boolean, arr: RecipientOptionObj[]) => {
    return arr.filter((t) => t.selected === isSelected)
  }
  const initialSuggested = getSelected(false, recipientOptions)

  const [suggested, setSuggested] =
    useState<RecipientOptionObj[]>(initialSuggested)

  const [selected, setSelected] = useState<RecipientOptionObj[]>([])
  useEffect(() => {
    const selected = getSelected(true, recipientOptions)
    setSelected(selected)
  }, [recipientOptions])

  useEffect(() => {
    setDisableSend(true)
    const toPatientId = selected.find((s) => !s.isProvider)?.apiId
    const toProviderIds = selected
      .filter((s) => s.isProvider)
      .map((s) => s.apiId)

    const attachmentArr = uploads.map((u: UploadObj) => {
      return {
        storageKey: u.key,
      }
    })

    let messageContentHTML
    if (editorState?.getCurrentContent()) {
      messageContentHTML = draftToHtml(
        convertToRaw(editorState?.getCurrentContent())
      )
    }

    const messageContentTxt = editorState
      .getCurrentContent()
      .getPlainText('\u0001')

    if (isNewMessage) {
      // send new message
      setSendMessageData({
        ...sendMessageData,
        toPatient: toPatientId,
        toProviders: toProviderIds,
        urgent: urgency,
        messageContent: messageContentHTML,
        attachments: attachmentArr,
      })
      if (messageContentTxt && (toPatientId || toProviderIds.length)) {
        setDisableSend(false)
      }
    } else {
      // send message in an existing thread
      setSendMessageData({
        ...sendMessageData,
        messageThreadId: threadId,
        urgent: urgency,
        messageContent: messageContentHTML,
        attachments: attachmentArr,
      })
      if (messageContentTxt) {
        setDisableSend(false)
      }
    }
  }, [recipientOptions, selected, suggested, urgency, editorState, uploads])

  // handle select an option
  const handleSelectRecipient = (recipient: RecipientOptionObj) => {
    if (!recipient.isUser) {
      return false
    }
    setSearchRecipientStr('')
    // as soons as 1 patient is selected, remove all patient options
    const theSelectedIndex = recipientOptions.findIndex(
      (r: RecipientOptionObj) =>
        r.apiId === recipient.apiId && r.isProvider === recipient.isProvider
    )
    recipientOptions[theSelectedIndex].selected = true

    setRecipientOptions([...recipientOptions])

    if (!recipient.isProvider) {
      const patientRemovedArr = recipientOptions.filter(
        (r: RecipientOptionObj) => r.isProvider
      )

      setSuggested([...patientRemovedArr])
    } else {
      const newSuggest = suggested.filter(
        (s: RecipientOptionObj) => !s.selected
      )
      setSuggested([...newSuggest])
    }
    setShowDropdown(false)
  }

  const handleDeleteRecipient = (recipient: RecipientOptionObj) => {
    if (!recipient.isProvider) {
      const theDeletedIndex = recipientOptions.findIndex(
        (r: RecipientOptionObj) =>
          // notice: a patient and a provider can have the same apiId
          r.apiId === recipient.apiId && !r.isProvider
      )
      recipientOptions[theDeletedIndex].selected = false
      setRecipientOptions([...recipientOptions])
      setSuggested([...getSelected(false, recipientOptions)])
    } else {
      const theDeletedIndex = recipientOptions.findIndex(
        (r: RecipientOptionObj) =>
          r.apiId === recipient.apiId && r.isProvider === recipient.isProvider
      )
      recipientOptions[theDeletedIndex].selected = false
      setRecipientOptions([...recipientOptions])

      setSuggested([...suggested, recipientOptions[theDeletedIndex]])
    }
  }

  const renderDropdown = () => {
    return (
      <div className={classes.msgRecipient__dropdown}>
        {suggested
          ?.filter((s) => s.isUser) //remove non-users
          ?.filter(
            (s: RecipientOptionObj) =>
              s.name
                ?.toLowerCase()
                .includes(searchRecipientStr.toLowerCase()) ||
              s.preferredName
                ?.toLowerCase()
                .includes(searchRecipientStr.toLowerCase())
          )
          .map((r: RecipientOptionObj) => {
            const name = r.preferredName ?? r.name
            let initials = name[0] + name[name.lastIndexOf(' ') + 1]
            initials = initials.toUpperCase()

            return (
              <button
                className={classes.msgRecipient_tag}
                key={`option-${name}-${r.apiId}`}
                onClick={() => handleSelectRecipient(r)}
                disabled={!r.isUser}
              >
                <div className={classes.msgRecipient_tag_left}>
                  <div
                    className={classes.msgRecipient_tag_avatar}
                    style={{
                      backgroundImage: r.photoUrl
                        ? `url(${r.photoUrl})`
                        : 'none',
                    }}
                  >
                    {r.photoUrl ? null : initials}
                  </div>
                </div>

                <div className={classes.msgRecipient_tag_right}>
                  <div>{name}</div>
                  <div className={classes.msgRecipient_tag_subtext}>
                    {r.mrn}
                  </div>
                </div>
              </button>
            )
          })}
      </div>
    )
  }

  const renderSelected = () => {
    return selected.map((s) => {
      const uniqueId = s.isProvider
        ? `provider-${s.apiId}`
        : `patient-${s.apiId}`

      return (
        <Box
          key={`selected-${s?.label}-${s?.apiId}`}
          style={{ display: 'inline-block' }}
        >
          <Chip
            className={classes.msgFrom_To_chip}
            label={s.label}
            onDelete={() => handleDeleteRecipient(s)}
            aria-describedby={uniqueId}
            onClick={async (e) => {
              setAnchor(e.currentTarget)
              s.isProvider && (await getProviderContactInfo(s.apiId))
              setClickedPopover({
                isProvider: s.isProvider,
                username: s.isProvider && s.name ? s?.name : s?.preferredName,
                photoUrl: s?.photoUrl,
                patientMrn: !s.isProvider ? s.mrn : '',
                providerRole: s.isProvider && s.roles ? s?.roles[0] : '',
                providerOrPatientId: s.apiId,
                uniqueId: uniqueId,
              })
            }}
          />
        </Box>
      )
    })
  }

  const renderChipPopover = () => {
    return (
      <PatientOrProviderDetails
        id='recipient-popover'
        open={!!anchor}
        handleClose={() => setAnchor(null)}
        anchorEl={anchor}
        aria-describedby='recipient-popover'
        type={
          clickedPopover?.isProvider
            ? PatientOrProvider.Provider
            : PatientOrProvider.Patient
        }
        user={{
          name: clickedPopover?.username,
          photoUrl: clickedPopover?.photoUrl,
          patientMrn: clickedPopover?.patientMrn,
          providerRole: clickedPopover?.providerRole,
          patientOrProvider: clickedPopover?.isProvider
            ? PatientOrProvider.Provider
            : PatientOrProvider.Patient,
        }}
        providerOrPatientId={clickedPopover?.providerOrPatientId}
        contact={providerContactInfo.email ? providerContactInfo : undefined}
      />
    )
  }

  const renderRecipients = () => {
    //  for an existing thread
    if (thread) {
      return (
        <>
          <div className={classes.msgCreate__userList}>
            {thread?.otherUsers?.map((u) => {
              return (
                <Chip
                  key={`${u.name}-${u.role}`}
                  label={u.name}
                  className={classes.msgFrom_To_chip}
                  onClick={async (e) => {
                    setAnchor(e.currentTarget)
                    !u.isPatient && (await getProviderContactInfo(u.id))
                    setClickedPopover({
                      isProvider: !u.isPatient,
                      username: u.name,
                      photoUrl: u?.photoUrl ? u?.photoUrl : '',
                      patientMrn: u.role,
                      providerRole: u.role,
                      providerOrPatientId: u.id,
                      uniqueId: `${u.isPatient ? 'patient' : 'provider'}-${
                        u.id
                      }`,
                    })
                  }}
                />
              )
            })}
          </div>
          {renderChipPopover()}
        </>
      )
    } else {
      // for new message
      return (
        <div className={classes.msgCreate__userList}>
          <div className={classes.msgRecipient_toLine}>
            {renderSelected()}
            {renderChipPopover()}
            <textarea
              value={searchRecipientStr}
              className={classes.msgRecipient_toLine_textarea}
              onChange={(e) => setSearchRecipientStr(e.target.value)}
              onFocus={() => recipientOptions.length && setShowDropdown(true)}
              placeholder='Type provider or patient name/MRN'
            />
          </div>

          {showDropdown ? renderDropdown() : null}
        </div>
      )
    }
  }

  //  handle attach button
  const uploadChangeHandler = async (event: any) => {
    const [uploadedFile] = event.target.files

    let src = ''
    if (uploadedFile) {
      src = URL.createObjectURL(uploadedFile)
    }

    try {
      const photo = await fetch(src)
      const photoBlob = await photo.blob()
      const photoStorage = await Storage.put(
        `message-attachments/${Date.now()}/${src?.slice(-4)}.${
          uploadedFile.type?.split('/')[1]
        }`,
        photoBlob
      )

      const uploadObj = {
        index: src?.slice(-8),
        type: uploadedFile.type,
        name: uploadedFile.name,
        src: src,
        key: photoStorage.key,
      }

      uploadObj && setUploads([...uploads, uploadObj])
    } catch (err) {
      console.error(err)
    }
  }

  const clearStates = () => {
    setAttachmentKeys && setAttachmentKeys([])
    setUploads([])
    setRecipientOptions([...initialRecipientOptions])
    setSuggested(getSelected(true, recipientOptions))
    setSendMessageData({
      toProviders: [],
      toPatient: 0,
      urgent: false,
      messageContent: '',
      attachments: [],
    })
    backToMessages()
  }

  // new messages: Back/send/discard btns
  const renderBackBtn = () => {
    const renderReplyBtn = () => {
      if (showReply && !isNewMessage) {
        return (
          <Button
            variant='contained'
            onClick={() => setShowReply(false)}
            style={{
              alignSelf: 'flex-end',
              justifySelf: 'flex-end',
              marginBottom: 10,
            }}
          >
            Reply
          </Button>
        )
      }
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: '-15px',
        }}
      >
        <NavigationLink
          label='Back to messages'
          direction='backward'
          handleClick={clearStates}
        />
        {renderReplyBtn()}
      </div>
    )
  }

  const renderActionBtns = () => {
    if (!showReply || isNewMessage)
      return (
        <div style={{ marginTop: 20 }}>
          <Button
            variant='contained'
            className={classes.msgThread__actions__btn}
            disabled={disableSend}
            // style={{
            //   borderColor: disableSend ? themeColors.gray50 : themeColors.blue,
            // }}
            onClick={async () => {
              setDisableSend(true)
              await sendMessage(sendMessageData)
              setDisableSend(false)
              clearStates()
              setIsNewMessage && setIsNewMessage(false)
            }}
          >
            Send
          </Button>
          <Button
            variant='outlined'
            onClick={clearStates}
            className={classes.msgThread__actions__btn}
            disabled={disableSend}
          >
            Discard
          </Button>
        </div>
      )
  }

  const renderEdior = () => {
    if (isNewMessage || !showReply)
      return (
        <div className={classes.msgReply_box_wrapper}>
          <div className={classes.msgCreate__userRow}>
            <div className={classes.msgCreate__label}>From:</div>
            <div className={classes.msgCreate__userList}>
              <Chip
                label={`${myProviderDetails?.preferredFirstName} ${myProviderDetails?.lastName}`}
                className={classes.msgFrom_To_chip}
                onClick={async (e: {
                  currentTarget: SetStateAction<HTMLElement | null>
                }) => {
                  setAnchor(e.currentTarget)
                  myProviderDetails?.providerId &&
                    getProviderContactInfo(myProviderDetails?.providerId)
                  myProviderDetails?.preferredFirstName &&
                    myProviderDetails?.photoUrl &&
                    myProviderDetails?.role &&
                    myProviderDetails?.providerId &&
                    setClickedPopover({
                      isProvider: true,
                      username: myProviderDetails?.preferredFirstName,
                      photoUrl: myProviderDetails?.photoUrl,
                      patientMrn: '',
                      providerRole: myProviderDetails?.role,
                      providerOrPatientId: myProviderDetails?.providerId,
                      uniqueId: `provider-${myProviderDetails?.providerId}`,
                    })
                }}
              />
            </div>
            <div className={classes.msgCreate__userRow__actions}>
              <IconButton
                className={classes.msgCreate__userRow__priorityBtn}
                color='primary'
                component='span'
                size='small'
                onClick={() => {
                  setUrgency(!urgency)
                }}
              >
                <FlagSvg
                  fill={urgency ? themeColors.red : themeColors.gray50}
                />
              </IconButton>
            </div>
          </div>
          <div className={classes.msgCreate__userRow}>
            <div className={classes.msgCreate__label}>To:</div>
            {renderRecipients()}
            <div className={classes.msgCreate__attachBtn}>
              <input
                style={{ display: 'none' }}
                id='attachFile'
                type='file'
                onChange={(e) => {
                  uploadChangeHandler(e)
                }}
              />
              <label
                htmlFor='attachFile'
                style={{
                  color: themeColors.blue,
                  fontSize: 14,
                  cursor: 'pointer',
                  paddingLeft: '50%',
                }}
              >
                Attach
              </label>
            </div>
            <div className={classes.msgCreate__userRow__actions}></div>
          </div>

          <div className={classes.msgEditor_attach_wrapper}>
            <Editor
              editorState={editorState}
              onEditorStateChange={(e: any) => {
                setEditorState && setEditorState(e)
              }}
              onFocus={() => {
                if (showDropdown) {
                  setSearchRecipientStr('')
                  setShowDropdown(false)
                }
              }}
              ref={editorRef}
              toolbar={{
                options: [
                  'inline',
                  'fontSize',
                  'fontFamily',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'emoji',
                  'link',
                ],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                link: {
                  // popupClassName: classes.rich_editor_link_popup, //can we override the style?
                  inDropdown: false,
                  showOpenOptionOnHover: true,
                  defaultTargetOption: '_self',
                  options: ['link'],
                },
                textAlign: { inDropdown: true },
                emoji: {
                  emojis: [
                    '😀',
                    '😁',
                    '😂',
                    '😃',
                    '😉',
                    '😋',
                    '😎',
                    '😍',
                    '😗',
                    '🤗',
                    '🤔',
                    '😣',
                    '😫',
                    '😴',
                    '😌',
                    '🤓',
                    '😛',
                    '😜',
                    '😠',
                    '😇',
                    '😷',
                    '😈',
                    '👻',
                    '😺',
                    '😸',
                    '😹',
                    '😻',
                    '😼',
                    '😽',
                    '🙀',
                    '🙈',
                    '🙉',
                    '🙊',
                    '👼',
                    '👮',
                    '🕵',
                    '💂',
                    '👳',
                    '🎅',
                    '👸',
                    '👰',
                    '👲',
                    '🙍',
                    '🙇',
                    '🚶',
                    '🏃',
                    '💃',
                    '⛷',
                    '🏂',
                    '🏌',
                    '🏄',
                    '🚣',
                    '🏊',
                    '⛹',
                    '🏋',
                    '🚴',
                    '👫',
                    '💪',
                    '👈',
                    '👉',
                    '👉',
                    '👆',
                    '🖕',
                    '👇',
                    '🖖',
                    '🤘',
                    '🖐',
                    '👌',
                    '👍',
                    '👎',
                    '✊',
                    '👊',
                    '👏',
                    '🙌',
                    '🙏',
                    '🐵',
                    '🐶',
                    '🐇',
                    '🐥',
                    '🐸',
                    '🐌',
                    '🐛',
                    '🐜',
                    '🐝',
                    '🍉',
                    '🍄',
                    '🍔',
                    '🍤',
                    '🍨',
                    '🍪',
                    '🎂',
                    '🍰',
                    '🌍',
                    '🚑',
                    '⏰',
                    '🌙',
                    '🌝',
                    '🌞',
                    '⭐',
                    '🌟',
                    '🌠',
                    '🌨',
                    '🌩',
                    '⛄',
                    '🔥',
                    '🎄',
                    '🎈',
                    '🎉',
                    '🎊',
                    '🎁',
                    '🎗',
                    '🏀',
                    '🏈',
                    '🎲',
                    '🔇',
                    '🔈',
                    '📣',
                    '🔔',
                    '🎵',
                    '🎷',
                    '💰',
                    '🖊',
                    '📅',
                    '✅',
                    '❎',
                    '💯',
                  ],
                },
              }}
              wrapperStyle={{
                padding: 0,
                margin: 0,
                maxWidth: '100%',
              }}
              editorStyle={{
                margin: 0,
                paddingRight: 15,
                height: '12rem',
                width: '100%',
              }}
              toolbarStyle={{
                border: 'none',
                margin: 0,
                padding: 0,
              }}
            />
          </div>

          {/* attachment preview */}
          <div className={classes.msgAttachments_preview_wrapper}>
            {uploads.length > 0 &&
              uploads.map((u: UploadObj, i: number) => {
                if (u.src) {
                  return (
                    <div
                      key={i}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: 20,
                      }}
                    >
                      <img
                        id='attachFile'
                        src={u.type.includes('image') ? u.src : FileSvg}
                        alt='uploaded file'
                        style={{
                          objectFit: 'contain',
                          height: '100px',
                          maxWidth: u.type.includes('image')
                            ? '200px'
                            : '100px',
                          borderRadius: '.5rem',
                        }}
                        key={`img-${i}`}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          maxWidth: 200,
                        }}
                      >
                        <button
                          type='button'
                          onClick={() => {
                            const toBeRemoved = uploads.findIndex(
                              (obj: UploadObj) => obj.index === u.index
                            )
                            if (toBeRemoved !== -1) {
                              uploads.splice(toBeRemoved, 1)
                              setUploads([...uploads])
                            }
                          }}
                          style={{
                            fontSize: 14,
                            border: 'none',
                            cursor: 'pointer',
                          }}
                          className={classes.msgThread__attachment}
                        >
                          <span
                            style={{ fontWeight: 'bolder', marginRight: 5 }}
                          >
                            {'\u00D7'}
                          </span>
                          <span>{u.name && u.name}</span>
                        </button>
                      </div>
                    </div>
                  )
                } else {
                  return null
                }
              })}
          </div>

          {renderActionBtns()}
        </div>
      )
  }

  // editor box
  return (
    <div>
      {renderBackBtn()}
      {renderEdior()}
    </div>
  )
}

export default MessageEditor
