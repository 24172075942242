import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Box, Button } from '@mui/material'

import { themeColors } from '../../theme'

export interface NavigationLinkProps {
  handleClick: () => void
  label: string
  direction?: 'forward' | 'backward'
}

export const NavigationLink = ({
  handleClick,
  label,
  direction = 'forward',
}: NavigationLinkProps) => {
  return (
    <div data-testid='navigationLink'>
      <Box
        sx={{
          backgroundColor: themeColors.white,
          position: 'sticky',
          height: 50,
          width: '100%',
          padding: '10px 15px',
          top: -15,
          zIndex: 100,
        }}
      >
        <Button
          onClick={handleClick}
          variant='text'
          startIcon={direction === 'backward' ? <ArrowBack /> : null}
          endIcon={direction === 'forward' ? <ArrowForward /> : null}
          sx={{ color: themeColors.blue40, fontSize: '14px' }}
        >
          {label}
        </Button>
      </Box>
    </div>
  )
}
