import { Badge, Typography } from '@mui/material'
import classNames from 'classnames'
import { classnames } from 'tss-react/tools/classnames'

import AttachmentSvg from '../../../../assets/icon.attachment.svg'
import CaretRightSvg from '../../../../assets/icon.caret-right.svg'
import RedFlag from '../../../../assets/icon.flag.svg'
import { MessageData, MessageUser } from '../../../../providers/Messages/types'
import { themeColors } from '../../../../theme'
import { dateFormatters } from '../../../../utilities/HelperFunctions'
import { useStyles } from './Messaging.styles'

type MessageProps = {
  message: MessageData
  keyValue: string
  isPreview: boolean
  unread?: boolean
  openThread?: () => void
  attachmentKeys?: { id: number; key: string }[]
  displayUser?: {
    name: string
    role: string
    photoUrl: string
    isPatient: boolean
    onCareTeam: boolean
  }
  displayThreadUserNames?: string
  displayUserCount?: number
}

const Message = ({
  message,
  keyValue,
  isPreview,
  unread,
  openThread,
  attachmentKeys,
  displayUser,
  displayThreadUserNames,
  displayUserCount,
}: MessageProps) => {
  const { classes } = useStyles()

  const messageClick = () => {
    if (openThread) {
      openThread()
    }
  }

  const renderAvatar = () => {
    if (isPreview && displayUser && displayUser.photoUrl) {
      return (
        <div
          className={classnames([
            classes.message__icon,
            classes.message__icon__image,
          ])}
          style={{
            backgroundImage: `url(${displayUser.photoUrl})`,
          }}
        ></div>
      )
    } else if (!isPreview && message.fromUser.photoUrl) {
      return (
        <div
          className={classnames([
            classes.message__icon,
            classes.message__icon__image,
          ])}
          style={{
            backgroundImage: `url(${message.fromUser.photoUrl})`,
          }}
        ></div>
      )
    } else {
      const nameBits =
        isPreview && displayUser
          ? displayUser.name.split(' ')
          : message.fromUser.name.split(' ')
      const initials =
        nameBits[0][0]?.toUpperCase() +
        nameBits[nameBits.length - 1][0]?.toUpperCase()

      return <div className={classes.message__icon}>{initials}</div>
    }
  }

  const renderToList = (users: MessageUser[]) => {
    return (
      <Typography className={classes.message__toList}>
        <span className={classes.message__toList__to}>To: </span>
        {users
          .map((item) => {
            return `${item.name} (${item.role})`
          })
          .join(', ')}
      </Typography>
    )
  }

  const renderFlags = () => {
    const flags = []

    if (isPreview && message.attachments.length) {
      flags.push(<img src={AttachmentSvg} alt='attachment' key='preview' />)
    }

    if (!isPreview) {
      if (message.urgent) {
        flags.push(
          <div key='urgent' className={classes.message__urgent}>
            <span>High Priority &nbsp;</span>
            <img
              src={RedFlag}
              alt='flag'
              style={{ marginLeft: 10 }}
              key='not-preview'
            />
          </div>
        )
      }
    } else if (message.urgent) {
      flags.push(
        <img src={RedFlag} alt='flag' key='urgent' style={{ marginLeft: 10 }} />
      )
    }

    if (isPreview) {
      flags.push(
        <img
          src={CaretRightSvg}
          alt='details'
          className={classes.message__detailsIcon}
          style={{ marginLeft: 10 }}
          key='preview-no-attachment'
        />
      )
    }
    return <div className={classes.message__flags}>{flags}</div>
  }

  const renderAttachments = () => {
    if (!isPreview) {
      return attachmentKeys?.map(
        (k: { id: number; key: string }, i: number) => {
          if (k.id === message.messageId) {
            return (
              <div
                key={i}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100px',
                }}
                className={classes.msgThread__attachment}
              >
                <img
                  src={AttachmentSvg}
                  alt={'attachment'}
                  style={{
                    marginRight: 8,
                  }}
                />
                <a href={k.key}>{k.key.split('?')[0].slice(-8)}</a>
              </div>
            )
          } else return null
        }
      )
    } else return ''
  }

  const renderHTML = (messageBody: string) => {
    if (isPreview) {
      if (messageBody?.replace(/<\/?[^>]+(>|$)/g, '').length > 60) {
        return `${messageBody?.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 60)} ...`
      } else return messageBody?.replace(/<\/?[^>]+(>|$)/g, '')
    } else {
      return messageBody
    }
  }

  return (
    <div
      className={classNames([
        classes.message,
        isPreview ? classes.message__isPreview : null,
      ])}
      key={keyValue}
      onClick={messageClick}
      aria-hidden='true'
    >
      {unread ? <div className={classes.message__unread} /> : ''}

      {displayUserCount && displayUserCount > 1 ? (
        <Badge
          badgeContent={`+${displayUserCount - 1}`}
          // color='primary'
          sx={{
            '& .MuiBadge-badge': {
              color: themeColors.white,
              backgroundColor: themeColors.gray50,
              width: '20px',
              height: '20px',
              borderRadius: '100%',
            },
          }}
          className={classnames([
            classes.message__icon,
            classes.message__icon__image,
          ])}
          style={{ backgroundColor: 'transparent' }}
        >
          {renderAvatar()}
        </Badge>
      ) : (
        renderAvatar()
      )}

      {isPreview ? (
        <Typography className={classes.message__providers}>
          {displayThreadUserNames && displayThreadUserNames?.length > 80
            ? `${displayThreadUserNames?.slice(0, 80)}...`
            : displayThreadUserNames}
        </Typography>
      ) : (
        <Typography className={classes.message__providers}>
          {`${message.fromUser?.name} (${message.fromUser?.role})`}
        </Typography>
      )}

      {isPreview ? null : renderToList(message.toUsers)}

      <Typography className={classes.message__timestamp}>
        {dateFormatters.prettyDateWithTime(message.receivedDateTime)}
      </Typography>

      <div
        style={{
          gridArea: '3 / 1 / 3 / 1',
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {renderAttachments()}
      </div>

      <Typography
        className={isPreview ? classes.message__preview : classes.message__text}
        dangerouslySetInnerHTML={{
          __html: renderHTML(message.messageBody),
        }}
      ></Typography>

      {renderFlags()}
    </div>
  )
}

export default Message
