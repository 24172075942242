import { useEffect, useState } from 'react'

import { useMessages } from '../../../../providers/Messages/Messages.provider'
import {
  MessageThreadData,
  UploadObj,
} from '../../../../providers/Messages/types'
import useInterval from '../../../../utilities/useIntervals'
import Message from './Message.view'
import MessageEditor from './MessageEditor.view'
import MessageThread from './MessageThread.view'
import { useStyles } from './Messaging.styles'

interface MessageTabs {
  myPatients: string
  providers: string
  otherPatients: string
}

const tabs: MessageTabs = {
  myPatients: 'My Patients',
  providers: 'Providers',
  otherPatients: 'All Patients',
}

const Messaging = () => {
  const { classes } = useStyles()
  const [activeTab, setActiveTab] = useState<keyof MessageTabs>('myPatients')
  const [threadId, setThreadId] = useState<number | null>(null)

  const {
    threads,
    getProviderMessageThreads,
    thread,
    getProviderMessagesInThread,
    attachmentKeys,
    setAttachmentKeys,
    isNewMessage,
    setIsNewMessage,
  } = useMessages()

  useEffect(() => {
    getProviderMessageThreads()
  }, [])

  useEffect(() => {
    threadId && getProviderMessagesInThread(threadId)
    getProviderMessageThreads()
  }, [threadId])

  useInterval(() => {
    getProviderMessageThreads()
  }, 6000)

  const goToThread = (thread: MessageThreadData) => {
    setThreadId(thread.messageThreadId)
  }

  const backToMessages = () => {
    setThreadId(null)
    setIsNewMessage(false)
  }

  const renderTabs = () => {
    return (
      <div className={classes.messaging__tabs}>
        {Object.keys(tabs).map((key: string) => {
          return (
            <button
              key={`tab${key}`}
              className={classes.messaging__tab}
              onClick={() => {
                setActiveTab(key as keyof MessageTabs)
                setThreadId(null)
              }}
              style={{ fontWeight: activeTab === key ? 'bold' : '' }}
            >
              {tabs[key as keyof MessageTabs]}
              &nbsp;
              {`(${
                threads[key as keyof MessageTabs]?.filter((m) => m.unread)
                  .length
              })`}
              {activeTab === key ? (
                <div className={classes.messaging__tab__active}></div>
              ) : null}
            </button>
          )
        })}
      </div>
    )
  }

  // threads list
  const renderMessageThreadPreviews = () => {
    if (threadId) {
      return null
    }
    // activeTab
    switch (activeTab) {
      case 'myPatients':
        return threads.myPatients.map((thread, idx) => (
          <div key={idx}>
            <Message
              message={thread.messages[0]}
              keyValue={`mp${idx}`}
              isPreview={true}
              unread={thread.unread}
              openThread={() => goToThread(thread)}
              displayUser={thread.displayUser}
              displayThreadUserNames={thread.displayThreadUserNames}
              displayUserCount={thread.displayUserCount}
            />
          </div>
        ))
      case 'providers':
        return threads.providers.map((thread, idx) => (
          <div key={idx}>
            <Message
              message={thread.messages[0]}
              keyValue={`mp${idx}`}
              isPreview={true}
              unread={thread.unread}
              openThread={() => goToThread(thread)}
              displayUser={thread.displayUser}
              displayThreadUserNames={thread.displayThreadUserNames}
              displayUserCount={thread.displayUserCount}
            />
          </div>
        ))
      default:
        return threads.otherPatients.map((thread, idx) => (
          <div key={idx}>
            <Message
              message={thread.messages[0]}
              keyValue={`mp${idx}`}
              isPreview={true}
              unread={thread.unread}
              openThread={() => goToThread(thread)}
              displayUser={thread.displayUser}
              displayThreadUserNames={thread.displayThreadUserNames}
              displayUserCount={thread.displayUserCount}
            />
          </div>
        ))
    }
  }

  const [uploads, setUploads] = useState<UploadObj[]>([])

  // thread detail
  const renderMessageThread = () => {
    if (!threadId) {
      return null
    }

    return (
      <MessageThread
        backToMessages={backToMessages}
        messageThread={thread}
        threadId={threadId}
        attachmentKeys={attachmentKeys}
        setAttachmentKeys={setAttachmentKeys}
        uploads={uploads}
        setUploads={setUploads}
      />
    )
  }

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 20,
        }}
      >
        {renderTabs()}
      </div>
      {isNewMessage ? (
        // render newMessage editing box
        <div style={{ padding: '0 14px' }}>
          <MessageEditor
            backToMessages={backToMessages}
            uploads={uploads}
            setUploads={setUploads}
            setAttachmentKeys={setAttachmentKeys}
            setIsNewMessage={setIsNewMessage}
            isNewMessage={isNewMessage}
          />
        </div>
      ) : (
        <div
          className={classes.messaging__list}
          style={{
            height: '90%',
            overflow: 'auto',
          }}
        >
          {renderMessageThreadPreviews()}
          {renderMessageThread()}
        </div>
      )}
    </div>
  )
}

export default Messaging
