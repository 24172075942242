import {
  Box,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material'

import { Mock } from '../../components'
import { themeColors } from '../../theme'
import { AdminSubview } from './types'

export interface AdminViewProps {
  menuItems: AdminSubview[]
  setSelectedMenuItem: React.Dispatch<React.SetStateAction<AdminSubview>>
  selectedMenuItem: AdminSubview
}

export const AdminView = ({
  menuItems,
  setSelectedMenuItem,
  selectedMenuItem,
}: AdminViewProps) => {
  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid
        item
        xs={2}
        sx={{
          position: 'relative',
          height: '100%',
          borderRight: `2px solid ${themeColors.gray90}`,
        }}
      >
        <Box
          sx={{
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            margin: 'auto',
            paddingTop: '12px',
          }}
        >
          <MenuList>
            <Box>
              <Typography sx={{ fontSize: '18px', textAlign: 'center' }}>
                Admin Functions
              </Typography>
            </Box>
            <Divider sx={{ marginBottom: '20px' }} />
            {menuItems
              .filter((mi) => !mi.restricted)
              .map((menuItem: AdminSubview) => (
                <MenuItem
                  key={menuItem.title}
                  onClick={() => setSelectedMenuItem(menuItem)}
                  sx={{ marginBottom: '20px', fontSize: '10px' }}
                >
                  <ListItemIcon>{menuItem.Icon}</ListItemIcon>
                  <ListItemText>{menuItem.title}</ListItemText>
                </MenuItem>
              ))}
          </MenuList>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box sx={{ padding: '20px', paddingLeft: '40px' }}>
          {selectedMenuItem?.View || (
            <Mock
              route={selectedMenuItem.title.toLowerCase() + ' admin function'}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
