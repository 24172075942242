import Man1 from '../../assets/mocks/Man1.png'
import Man2 from '../../assets/mocks/Man2.png'
import Man3 from '../../assets/mocks/Man3.png'
import Man4 from '../../assets/mocks/Man4.png'
import Woman1 from '../../assets/mocks/Woman1.png'
import Woman2 from '../../assets/mocks/Woman2.png'
import Woman3 from '../../assets/mocks/Woman3.png'
import Woman4 from '../../assets/mocks/Woman4.png'
import Woman5 from '../../assets/mocks/Woman5.png'
import Woman6 from '../../assets/mocks/Woman6.png'
import { Participant } from '../../providers/Schedule/types'
import { User } from '../../providers/User/types'

const avatars: { male: string[]; female: string[] } = {
  male: [Man1, Man2, Man3, Man4],
  female: [Woman1, Woman2, Woman3, Woman4, Woman5, Woman6],
}

const getRandomAvatar = (gender: 'male' | 'female'): string =>
  avatars[gender][Math.floor(Math.random() * avatars[gender].length)]

export const getRandomProviderRole = () =>
  [
    'Navigator',
    'Nurse',
    'Peer Guide',
    'Ally’s Peer Guide',
    'Advanced Practice Clinician',
    'Physician',
  ][Math.floor(Math.random() * 6)]

export const mockUsers: User[] = [
  {
    firstName: 'Michael',
    preferredFirstName: 'Michael',
    lastName: 'Lawson',
    photoUrl: getRandomAvatar('male'),
    emailAddress: 'a@b.com',
  },
  {
    firstName: 'Lindsay',
    preferredFirstName: 'Lindsay',
    lastName: 'Ferguson',
    photoUrl: getRandomAvatar('female'),
    emailAddress: 'a@b.com',
  },
  {
    firstName: 'Tobias',
    preferredFirstName: 'Tobias',
    lastName: 'Funke',
    photoUrl: getRandomAvatar('male'),
    emailAddress: 'a@b.com',
  },
  {
    firstName: 'Byron',
    preferredFirstName: 'Byron',
    lastName: 'Fields',
    photoUrl: getRandomAvatar('male'),
    emailAddress: 'a@b.com',
  },
  {
    firstName: 'George',
    preferredFirstName: 'Georgie',
    lastName: 'Edwards',
    photoUrl: getRandomAvatar('male'),
    emailAddress: 'a@b.com',
  },
  {
    firstName: 'Rachel',
    preferredFirstName: 'Rachel',
    lastName: 'Howell',
    photoUrl: getRandomAvatar('female'),
    emailAddress: 'a@b.com',
  },
  {
    firstName: 'Donna',
    preferredFirstName: 'Donna',
    lastName: 'Raite',
    photoUrl: getRandomAvatar('female'),
    emailAddress: 'a@b.com',
  },
  {
    firstName: 'Wanda',
    preferredFirstName: 'Wanda',
    lastName: 'Horstich',
    photoUrl: getRandomAvatar('female'),
    emailAddress: 'a@b.com',
  },
  {
    firstName: 'Bri',
    preferredFirstName: 'Bri',
    lastName: 'Moore',
    photoUrl: getRandomAvatar('female'),
    emailAddress: 'a@b.com',
  },
  {
    firstName: 'Frankie',
    preferredFirstName: 'Frankie',
    lastName: 'Purse',
    photoUrl: getRandomAvatar('female'),
    emailAddress: 'a@b.com',
  },
]

export const generateRandomParticipant = ({
  role,
}: {
  role: 'patient' | 'provider'
}): Participant => {
  const randomPerson = mockUsers[Math.floor(Math.random() * mockUsers.length)]

  return {
    participantName: `${randomPerson.firstName} ${randomPerson.lastName}`,
    participantRole: role,
    participantPhotoURL: randomPerson.photoUrl,
  }
}
