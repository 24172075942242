import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { createContext, ReactNode, useContext, useState } from 'react'

import {
  divideScheduleIntoDaysWithAppointments,
  getStartAndEndDateFromMonthYear,
} from '../../utilities/HelperFunctions'
import { useAxios } from '../../utilities/Requests/useAxios'
import { Appointment, DayWithAppointments } from './types'

export interface ScheduleContextInterface {
  sevenDayAppointments: Appointment[]
  daysWithAppointments: DayWithAppointments[]
  get7dayAppointments: (startDate: Date, numberOfDays?: number) => Promise<any>
  getDaysWithAppointments: (month: number, year: number) => Promise<any>
  hideAppointment: (appointmentId: number) => Promise<any>
}

export const ScheduleContext = createContext<ScheduleContextInterface | null>(
  null
)

export const ScheduleProvider = ({ children }: { children?: ReactNode }) => {
  const { fetch } = useAxios()

  const [sevenDayAppointments, set7dayAppointments] = useState<Appointment[]>(
    []
  )
  const [daysWithAppointments, setDaysWithAppointments] = useState<any>([])

  const get7dayAppointments = async (startDate: Date, numberOfDays = 1) => {
    try {
      const { data } = await fetch({
        path: `Schedule/GetSchedule?StartDate=${dayjs(startDate.toDateString())
          .subtract(1, 'day')
          .toISOString()}&NumberOfDays=${3}&PeerGroupsOnly=false`,
      })

      if (data) {
        const modifiedForDisplay = await data
          .filter(
            (appointment: Appointment) =>
              dayjs(appointment.startTime).date() === dayjs(startDate).date()
          )
          .map(async (appointment: Appointment) => {
            const participants = appointment.participants.map(async (p) => ({
              ...p,
              participantPhotoURL: p.participantPhotoURL
                ? await Storage.get(p.participantPhotoURL)
                : p.participantPhotoURL,
            }))

            const observers =
              appointment.observers?.map(async (p) => ({
                ...p,
                participantPhotoURL: p.participantPhotoURL
                  ? await Storage.get(p.participantPhotoURL)
                  : p.participantPhotoURL,
              })) || []

            const patients = appointment.patients?.map(async (p) => ({
              ...p,
              photoURL: p.photoURL ? await Storage.get(p.photoURL) : p.photoURL,
            }))

            if (appointment.peerGroupId) {
              const { data } = await fetch({
                path: `PeerGroup/GetPeerGroupForEdit?peerGroupId=${appointment.peerGroupId}`,
              })

              if (data && data.seats) {
                appointment.seatsAvailable = data.seats
              }
            }

            return {
              ...appointment,
              id: appointment.appointmentId,
              name: appointment.appointmentName,
              participants: await Promise.all(participants),
              observers: await Promise.all(observers),
              patients: await Promise.all(patients),
            }
          })
        const finalizedAppointments = await Promise.all(modifiedForDisplay)
        set7dayAppointments([...finalizedAppointments])
      }
    } catch (e: any) {
      console.error(e)
    }
  }

  const getDaysWithAppointments = async (month: number, year: number) => {
    setDaysWithAppointments([])
    const { startDate, endDate } = getStartAndEndDateFromMonthYear(month, year)

    try {
      const { data } = await fetch({
        path: `Schedule/GetScheduledDateTimes?StartDate=${startDate}&EndDate=${endDate}&PeerGroupsOnly=false`,
      })
      if (data) {
        const sortedDays = divideScheduleIntoDaysWithAppointments(data)
        setDaysWithAppointments(sortedDays)
      }
    } catch (e: any) {
      console.error(e)
    }
  }

  const hideAppointment = async (appointmentId: number) => {
    try {
      await fetch({
        path: `Schedule/HideAppointment`,
        methodType: 'POST',
        body: { appointmentId },
      })
    } catch (e: any) {
      console.error(e)
    }
  }

  return (
    <ScheduleContext.Provider
      value={{
        sevenDayAppointments,
        daysWithAppointments,
        get7dayAppointments,
        getDaysWithAppointments,
        hideAppointment,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  )
}

export const useSchedule = () =>
  useContext(ScheduleContext) as ScheduleContextInterface
