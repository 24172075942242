import { Box } from '@mui/material'

import { SchedulingLinkTabPanelProps } from '../types'
import { DetailRow } from './SchedulingLink.partial'

export const SchedulingLinkTabPanel = ({
  primaryScheduleLink,
  scheduleLinks,
}: SchedulingLinkTabPanelProps) => {
  return (
    <Box>
      {/* <DetailRow primaryLink={primaryScheduleLink && primaryScheduleLink} /> */}
      {scheduleLinks?.map((sl, i) => (
        <div key={i}>
          <DetailRow scheduleLinkObj={sl} />
        </div>
      ))}
    </Box>
  )
}
