import { Grid } from '@mui/material'

import { Widget } from '../../components'
import { useStyles } from './Dashboard.styles'
import { Widget as WidgetType } from './Widgets/types'

interface DashboardViewProps {
  widgets: WidgetType[]
}

export const DashboardView = ({ widgets }: DashboardViewProps) => {
  const { classes } = useStyles()

  return (
    <div data-testid='dashboard' className={classes.dashboardContainer}>
      <Grid container sx={(theme) => ({ height: `100%` })} spacing={3}>
        {widgets.map((widget) => {
          const { Provider, Button } = widget

          return (
            <Grid
              key={widget.name}
              item
              xs={12}
              lg={widget.width === 'half' ? 6 : 12}
              sx={{ height: widget.height === 'half' ? '60%' : '100%' }}
            >
              <Provider>
                <Widget
                  title={widget.name}
                  Button={Button}
                  usePadding={widget.usePadding}
                >
                  <>{widget.element}</>
                </Widget>
              </Provider>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}
