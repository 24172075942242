import { Avatar, Grid, Typography } from '@mui/material'

import { themeColors } from '../../theme'
import { getUserInitials } from '../../utilities/HelperFunctions'
import {
  PatientOrProvider,
  PopOutUser,
} from '../PatientOrProviderDetails/types'

export interface UserDetailBlockProps {
  user: PopOutUser
}

export const UserDetailBlock = ({
  user: { name, photoUrl, providerRole, patientMrn, patientOrProvider },
}: UserDetailBlockProps) => (
  <Grid
    container
    sx={{ marginTop: '20px', maxWidth: '420px' }}
    justifyContent='flex-start'
    alignItems='center'
  >
    <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Avatar
        sx={{
          bgcolor: themeColors.gray40,
          color: themeColors.white,
          width: 50,
          height: 50,
        }}
        alt={name}
        src={photoUrl}
      >
        {name ? getUserInitials(name) : ''}
      </Avatar>
    </Grid>

    <Grid
      item
      xs={6}
      sx={{
        marginLeft: '10px',
      }}
    >
      <Typography
        sx={{
          color: themeColors.grayblack,
          fontSize: '13px',
          fontWeight: '700',
          margin: 0,
        }}
      >
        {name}
      </Typography>
      <Typography
        sx={{
          color: themeColors.gray40,
          fontSize: '13px',
          fontWeight: '700',
          margin: 0,
        }}
      >
        {patientOrProvider === PatientOrProvider.Provider
          ? providerRole
          : patientMrn}
      </Typography>
    </Grid>
  </Grid>
)
