import '@aws-amplify/ui-react/styles.css'

import { View } from '@aws-amplify/ui-react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Input,
  LinearProgress,
  Link,
  Paper,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { AuthLogoView, AuthSideView } from './Auth.compositions'
import { Step } from './Auth.container'
import { useStyles } from './Auth.styles'

interface AuthViewProps {
  steps: Step[]
  activeStepIndex: number
  isDisabled: boolean
  setIsDisabled: (isDisabled: boolean) => void
}

interface StepProps {
  step: Step
  isDisabled: boolean
  setIsDisabled: (isDisabled: boolean) => void
}

const AuthStep = ({ step, isDisabled, setIsDisabled }: StepProps) => {
  const { classes } = useStyles()
  const [isResending, setIsResending] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, submitCount, dirtyFields },
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    const init = async () => {
      setIsLoading(true)
      await step.initializeAction!()
      setIsLoading(false)
    }

    if (step.initializeAction) {
      init()
    }
  }, [])

  const onSubmit = async (data: any) => {
    try {
      await step.action(data)
    } catch (e: any) {
      setIsDisabled(false)
      setError('global', {
        type: 'custom',
        message: step.errorText(submitCount),
      })
    }
  }

  const onResend = async () => {
    setIsResending(true)
    try {
      await step.tangentialAction!.action()
    } catch (err) {
      setError('global', {
        type: 'custom',
        message: step.tangentialAction!.errorText(submitCount),
      })
    }
    setTimeout(() => {
      setIsResending(false)
    }, 1000)
  }

  return (
    <Paper className={classes.moduleContainer}>
      <Box
        sx={(theme) => ({
          width: '70%',
          margin: 'auto',
          marginBottom: theme.spacing(2),
        })}
      >
        <Typography variant='h2'>{step.header}</Typography>
        <Typography variant='body1'>{step.helpText}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '90%',
          margin: 'auto',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <form
            className={classes.formContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            {errors['global'] && (
              <Typography
                variant='subtitle1'
                maxWidth='100%'
                width='100%'
                color={(theme) => theme.palette.error.main}
                pb={3}
              >
                {errors['global'].message}
              </Typography>
            )}
            {step.component && step.component({ setIsDisabled, clearErrors })}
            {step.fields.map((field) => (
              <Box
                key={field.id}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '80px',
                  }}
                >
                  <Typography variant='subtitle2'>{field.label}:</Typography>
                  <Input
                    type={field.type ?? 'text'}
                    disableUnderline
                    {...register(field.id, {
                      required: !!field.required,
                      pattern: field.pattern,
                      value: field.value,
                    })}
                    sx={{ width: '100%', letterSpacing: field.isCode ? 25 : 1 }}
                    onKeyUp={(e) => clearErrors('global')}
                  />
                </Box>
                {errors[field.id] && (submitCount || dirtyFields[field.id]) && (
                  <Typography
                    variant='subtitle1'
                    color={(theme) => theme.palette.error.main}
                    pb={3}
                  >
                    {[field.label]} is required.
                  </Typography>
                )}
              </Box>
            ))}
            <Box sx={{ width: '100%' }} mt={1} mb={1}>
              {!!step.tangentialAction &&
                (!isResending ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    component='button'
                    underline='hover'
                    color={(theme) => theme.palette.info.main}
                    type='button'
                    onClick={onResend}
                  >
                    {step.tangentialAction.label}
                  </Link>
                ) : (
                  <LinearProgress color='info' sx={{ width: '100%' }} />
                ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '40px',
              }}
            >
              <Button
                variant='contained'
                type='submit'
                disabled={!!Object.keys(errors).length || isDisabled}
                sx={(theme) => ({ width: theme.spacing(20) })}
              >
                {step.actionText ?? 'Submit'}
              </Button>
            </Box>
            <Box sx={{ textAlign: 'center' }} mt={2}>
              {!!step.redirectAction && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  component='button'
                  underline='hover'
                  color={(theme) => theme.palette.info.main}
                  type='button'
                  onClick={step.redirectAction.action}
                >
                  {step.redirectAction.label}
                </Link>
              )}
            </Box>
          </form>
        )}
      </Box>
    </Paper>
  )
}

export const AuthView = ({
  steps,
  activeStepIndex,
  isDisabled,
  setIsDisabled,
}: AuthViewProps) => {
  return (
    <Grid container style={{ overflowY: 'hidden' }}>
      <Grid
        item
        md={5}
        sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}
      >
        <AuthSideView />
      </Grid>
      <Grid
        item
        md={7}
        sm={12}
        style={{ height: '100vh', overflowY: 'auto', paddingBottom: '50px' }}
      >
        <Grid container direction='column'>
          <Grid item p={5}>
            <AuthLogoView />
          </Grid>
          <Grid item>
            <View>
              <>
                {steps.map(
                  (step, i) =>
                    activeStepIndex === i && (
                      <AuthStep
                        step={step}
                        key={step.name}
                        isDisabled={isDisabled}
                        setIsDisabled={setIsDisabled}
                      />
                    )
                )}
              </>
            </View>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
