import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import { useEffect, useState } from 'react'

import { usePatientCareTeam } from '../../providers/CareTeam/PatientCareTeam.provider'
import { useProviderDetails } from '../../providers/ProviderDetails/ProviderDetails.provider'
import { themeColors } from '../../theme'
import { DetailsPopout } from '../DetailsPopout/DetailsPopout'
import { UserDetailBlock } from '../UserDetailBlock/UserDetailBlock'
import { CareTeamPanel } from './Tabs/CareTeam.tab'
import { ContactTabPanel } from './Tabs/Contact.tab'
import { SchedulingLinkTabPanel } from './Tabs/SchedulingLink.tab'
import { PatientOrProvider, PatientOrProviderDetailsProps } from './types'

export const PatientOrProviderDetails = ({
  id,
  type,
  open,
  handleClose,
  anchorEl,
  user,
  contact,
  providerOrPatientId,
  myPopover,
}: PatientOrProviderDetailsProps) => {
  const { patientCareTeam, getPatientCareTeam } = usePatientCareTeam()
  const { providerInformation, getProviderInformation, myProviderDetails } =
    useProviderDetails()

  useEffect(() => {
    myPopover !== undefined &&
      myProviderDetails?.providerId &&
      getProviderInformation(myProviderDetails?.providerId)
  }, [myPopover, providerOrPatientId, type])

  useEffect(() => {
    if (type === PatientOrProvider.Provider && providerOrPatientId) {
      getProviderInformation(providerOrPatientId)
    }
    if (type === PatientOrProvider.Patient && providerOrPatientId)
      getPatientCareTeam(providerOrPatientId)
  }, [providerOrPatientId, type, myPopover])

  const providerTabs = [
    {
      id: 'SchedulingLinks',
      label: 'Scheduling Links',
      TabDetails: (
        <SchedulingLinkTabPanel
          primaryScheduleLink={providerInformation?.primaryGRemindersLink}
          //@ts-ignore
          scheduleLinks={providerInformation?.gRemindersEventTypeLinks}
        />
      ),
    },
    {
      id: 'Contact',
      label: 'Contact',
      TabDetails: <ContactTabPanel contact={contact || undefined} />,
    },
  ]

  const patientTabs = [
    {
      id: 'CareTeam',
      label: 'Care Team',
      TabDetails: <CareTeamPanel careTeamEntities={patientCareTeam} />,
    },
  ]

  const tabsToUse =
    type === PatientOrProvider.Patient ? patientTabs : providerTabs

  const [tabState, setTabState] = useState<string>(tabsToUse[0].id)

  const handleChange = (_: any, newValue: string) => {
    setTabState(newValue)
  }

  useEffect(() => {
    if (!tabsToUse.find((x) => x.id === tabState)) {
      setTabState(tabsToUse[0].id)
    }
  }, [tabsToUse, providerOrPatientId])

  return (
    <div data-testid='patientOrProviderDetails'>
      <DetailsPopout
        {...{
          id,
          open,
          anchorEl,
          handleClose,
        }}
        width={tabsToUse === patientTabs ? '360px' : '300px'}
      >
        <Box
          sx={{
            paddingBottom: '20px',
            borderBottom: `1px solid ${themeColors.gray70}`,
            paddingLeft: '20px',
          }}
        >
          <UserDetailBlock {...{ user }} />
        </Box>

        <TabContext value={tabState}>
          <TabList
            onChange={handleChange}
            aria-label='Details Tabs'
            sx={{
              width: '100%',
              padding: '0 15px',
            }}
            variant={tabsToUse === patientTabs ? undefined : 'fullWidth'}
            TabIndicatorProps={{
              style: {
                backgroundColor:
                  tabsToUse === patientTabs ? 'white' : undefined,
              },
            }}
            indicatorColor={'secondary'}
          >
            {tabsToUse.map((tab) => (
              <Tab
                label={tab.label}
                value={tab.id}
                key={tab.id}
                sx={{ fontSize: '13px' }}
              />
            ))}
          </TabList>

          {tabsToUse.map(({ id, TabDetails }) => (
            <TabPanel
              value={id}
              key={id}
              sx={{
                padding: tabsToUse === patientTabs ? '0 16px' : '15px 25px',
              }}
            >
              <>{TabDetails}</>
            </TabPanel>
          ))}
        </TabContext>
      </DetailsPopout>
    </div>
  )
}
