import {
  AddPhotoAlternate,
  AudioFile,
  Image,
  PictureAsPdf,
  VideoFile,
} from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import Dropzone from 'react-dropzone'

import { PeerGroupMedia } from '../../providers/PeerGroups/types'
import { themeColors } from '../../theme'
import { FileList } from '../FileList/FileList'

export enum FileType {
  Audio = 'AUDIO',
  Photo = 'PHOTO',
  PDF = 'PDF',
  Video = 'VIDEO',
}

export const FileTypeIconMap = {
  [FileType.Audio]: <AudioFile />,
  [FileType.Photo]: <Image />,
  [FileType.PDF]: <PictureAsPdf />,
  [FileType.Video]: <VideoFile />,
}

export const FileTypeMap = {
  [FileType.Audio]: ['audio/mpeg', 'audio/webm'],
  [FileType.Photo]: [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp',
  ],
  [FileType.PDF]: ['application/pdf'],
  [FileType.Video]: ['video/mp4', 'video/mpeg', 'video/webm'],
}

export const getFileType = (file: File): FileType => {
  const nativeFileType = file.type
  return Object.entries(FileTypeMap)
    .filter(([k, v]) => v.includes(nativeFileType))
    .map(([k, v]) => k)[0] as FileType
}

export interface ImageUploadProps {
  handleNewImage: (acceptedImages: File[]) => void
  includeFileList?: boolean
  files?: PeerGroupMedia[]
  handle360Change?: (checked: boolean, file: PeerGroupMedia) => void
}

export const ImageUpload = ({
  handleNewImage,
  includeFileList = false,
  files,
  handle360Change,
}: ImageUploadProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '5px',
        border: `1px solid ${themeColors.gray70}`,

        '&:hover': {
          border: `1px solid ${themeColors.gray20}`,
        },
      }}
    >
      <Box
        sx={{
          padding: '14px 8px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: includeFileList ? '40%' : '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Dropzone onDrop={(acceptedFiles) => handleNewImage(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <Box
                sx={{
                  backgroundColor: includeFileList
                    ? themeColors.gray99
                    : 'none',
                  padding: '20px',
                  borderRadius: '8px',
                }}
              >
                <Box
                  {...getRootProps()}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <AddPhotoAlternate
                    fontSize='large'
                    sx={{ marginBottom: '10px' }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '24px',
                      color: themeColors.gray20,
                    }}
                  >
                    Drag and drop files here {includeFileList && 'or'}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {!includeFileList && (
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: '14px',
                          lineHeight: '24px',
                          color: themeColors.gray20,
                        }}
                      >
                        or
                      </Typography>
                    )}
                    <Button variant='contained' sx={{ margin: '0 5px' }}>
                      <input {...getInputProps()} hidden />
                      Pick File
                    </Button>
                  </Box>
                  {!includeFileList && (
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: themeColors.gray20,
                      }}
                    >
                      to begin the upload
                    </Typography>
                  )}
                </Box>
              </Box>
            )}
          </Dropzone>
        </Box>

        {includeFileList && (
          <Box sx={{ width: '50%' }}>
            <FileList files={files} handle360Change={handle360Change} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
