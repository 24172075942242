import { Storage } from 'aws-amplify'
import { useEffect } from 'react'

import {
  MessageData,
  MessageThreadData,
  UploadObj,
} from '../../../../providers/Messages/types'
import Message from './Message.view'
import MessageEditor from './MessageEditor.view'

type MessageThreadProps = {
  messageThread: MessageThreadData
  backToMessages: () => void
  threadId: number
  attachmentKeys?: { id: number; key: string }[]
  setAttachmentKeys?: React.Dispatch<
    React.SetStateAction<{ id: number; key: string }[]>
  >
  uploads: UploadObj[]
  setUploads: React.Dispatch<React.SetStateAction<any>>
}

const MessageThread = ({
  backToMessages,
  messageThread,
  threadId,
  attachmentKeys,
  setAttachmentKeys,
  uploads,
  setUploads,
}: MessageThreadProps) => {
  // attachment for reply box
  const getAttachmentKeys = () => {
    const newAttachments: { id: number; key: string }[] = []
    messageThread.messageThreadId &&
      messageThread.messages.forEach((message: MessageData) => {
        message.attachments.forEach(async (a: { storageKey: string }) => {
          const s3Key = await Storage.get(a.storageKey)
          newAttachments?.push({
            id: message.messageId,
            key: s3Key,
          })
          // @ts-ignore
          setAttachmentKeys([...newAttachments])
        })
      })
  }

  useEffect(() => {
    getAttachmentKeys()
  }, [messageThread, threadId])

  const renderReplyBox = () => {
    return (
      <MessageEditor
        backToMessages={backToMessages}
        uploads={uploads}
        setUploads={setUploads}
        threadId={threadId}
        thread={messageThread}
      />
    )
  }

  const renderMessages = () => {
    return messageThread.messages.map((item: MessageData, idx) => {
      return (
        <div key={idx}>
          <Message
            message={item}
            keyValue={`mp${idx}`}
            isPreview={false}
            attachmentKeys={attachmentKeys}
          />
        </div>
      )
    })
  }

  return (
    <div style={{ padding: '0 16px' }}>
      {renderReplyBox()}

      {renderMessages()}
    </div>
  )
}

export default MessageThread
