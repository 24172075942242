import { AnySchema } from 'yup'

export enum OptionsSelector {
  ProviderCredentials = 'providerCredentials',
  States = 'states',
  StatesWithoutId = 'statesWithoutId',
  MeetingCategories = 'meetingCategories',
  Providers = 'providers',
  ProviderUsers = 'providerUsers',
  TimeZones = 'timeZones',
  ScheduleFrequencies = 'scheduleFrequencies',
  PeerGroupBackgrounds = 'peerGroupBackgrounds',
}

export enum FieldType {
  Text,
  TextArea,
  Autocomplete,
  Multiselect,
  Radio,
  Date,
  Time,
  Media,
  Number,
  QuestionGroup,
  ImageSelector,
}

export type BaseOption = {
  label: string
  value: string | boolean | number
}

export type Field = {
  label: string
  subLabels?: string[]
  backendKey: string
  renderKey?: string
  displayKey?: string
  initialValue?: any
  maxValue?: number
  editComponent?: JSX.Element
  viewComponent?: JSX.Element | null
  isPatientVisible: boolean
  required: boolean
  isEditable: boolean
  maxWidthPercent?: number
  minWidthPercent?: number
  minWidth?: number
  validation?: AnySchema
  optionsSelector?: OptionsSelector
  observe?: string
  fieldType?: FieldType
  watch?: boolean
  multiline?: boolean
  chipLabelKey?: string
  options?: BaseOption[]
}

export type Section = {
  id?: string
  hideByDefault?: boolean
  dependentOn?: { key: string; being: boolean }
  sectionLabel?: string
  sectionSplit?: boolean
  fields: Field[]
  hasInstructions?: boolean
}

export type SectionFields = Section[]
