import { Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useStyles } from './NavLink.styles'

interface NavLinkProps {
  Icon: JSX.Element
  title: string
  route: string
}

export const NavLink = ({ Icon, title, route }: NavLinkProps) => {
  const { classes } = useStyles()
  const go = useNavigate()

  return (
    <div data-testid='navLink'>
      <Grid
        container
        onClick={() => go('/' + route)}
        className={classes.navContainer}
      >
        <div className={classes.navIcon}>{Icon}</div>
        <div className={classes.navText}>
          <Typography variant='button' align='left'>
            {title}
          </Typography>
        </div>
      </Grid>
    </div>
  )
}
