import { Box, Checkbox, Chip, Grid, Typography } from '@mui/material'

import { PeerGroupMedia } from '../../providers/PeerGroups/types'
import { themeColors } from '../../theme'
import { truncateFileName } from '../../utilities/HelperFunctions'
import { FileType, FileTypeIconMap } from '../ImageUpload/ImageUpload'

export interface FileListProps {
  files?: PeerGroupMedia[]
  handle360Change?: (checked: boolean, file: PeerGroupMedia) => void
  fullFileNames?: boolean
}

export const FileCard = ({
  file,
  fullFileNames,
}: {
  file: PeerGroupMedia
  fullFileNames: boolean
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '30px',
    }}
  >
    <Chip
      icon={FileTypeIconMap[file.fileType]}
      label={fullFileNames ? file.fileName : truncateFileName(file.fileName)}
      variant='outlined'
    />
  </Box>
)

export const FileCheckBox = ({
  file,
  handle360Change,
}: {
  file: PeerGroupMedia
  handle360Change?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '30px',
    }}
  >
    {(file.fileType === FileType.Photo || file.fileType === FileType.Video) &&
    handle360Change ? (
      <Checkbox checked={file.is360} onChange={handle360Change} size='small' />
    ) : (
      <Checkbox
        disabled
        checked={file.is360}
        onChange={handle360Change}
        size='small'
      />
    )}
  </Box>
)

export const FileList = ({
  files,
  handle360Change,
  fullFileNames = false,
}: FileListProps) => {
  return (
    <Grid container>
      <Grid item xs={9}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            color: themeColors.gray20,
          }}
        >
          {handle360Change ? 'Media:' : 'File:'}
        </Typography>
        <Box>
          {files?.map((file) => (
            <FileCard
              key={file.fileName}
              file={file}
              fullFileNames={fullFileNames}
            />
          ))}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            color: themeColors.gray20,
          }}
        >
          Is 360?:
        </Typography>
        <Box>
          {files?.map((file) => (
            <FileCheckBox
              key={file.fileName}
              file={file}
              handle360Change={
                handle360Change
                  ? (e) => handle360Change(e.target.checked, file)
                  : undefined
              }
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  )
}
